import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging
} from 'devextreme-react/data-grid';
import moment from 'moment-timezone';
import * as DOMPurify from 'dompurify';
import TenantService from 'api/tenant.service';
import Toast from 'components/Toast';
import './gateway.css';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
import { DateBox } from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import GatewayService from 'api/gateway.service';

import {
    Chart,
    CommonSeriesSettings,
    Legend,
    SeriesTemplate,
    ArgumentAxis,
    Size,
    ValueAxis,
    Tick,
    Series as ChartSeries
  } from 'devextreme-react/chart';

export default function GatewayDetail(props) {
  const gatewayId = props.gatewayId;
  const downloadTargetRef = useRef();
  const [data, setData] = useState([])
  const [loadingText, setLoadingText] = useState('Loading data...');
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState('Last 24 hours');
  const tenant = TenantService.getTenant()
  const dropDataGridRef = useRef();
  const dateBoxRefStartEvent = useRef(null);
  const dateBoxRefEndEvent = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageName, setPageName] = useState("");
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 1000,
  });
  const currentDate = new Date();

  async function getData() {
    setData([])
    GatewayService.getGatewayData(gatewayId).then((value) => {
      setPageName(value.data.data.name)
    })
    
    const fromDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const toDate = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (fromDate === "Invalid date" || toDate === "Invalid date") return true;
      await GatewayService.getGatewayDetail(gatewayId, fromDate, toDate ).then(async (value) => {
        if(value.data === null) return;
        if (value?.data?.data.length === 0) setLoadingText("No Data")
        else setLoadingText("Loading data...")
        const gatewayData = value.data.data
        await gatewayData.forEach(function(result) {
          result.start = new Date(result.start);
          result.end = new Date(result.end);
        });
        console.log('gateway Data: ', gatewayData);
        setData(gatewayData);
      })
    
  }

  useEffect(() => {
    const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
    if (periodVal !== null && periodVal !== 'null') {
      if (periodVal === 'Custom') {
          const startDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`));
          const endDate = moment(localStorage.getItem(`${tenant}dateFilterTo`));
          setStartDate(startDate);
          setEndDate(endDate);
      }
      handleValueChange(periodVal);
    } else {
      handleValueChange('Last 24 hours')
    }
    console.log('log 87');
    getData();
}, []);

  const handleStartDate = () => {
    dateBoxRefStartEvent.current.instance.open()
    setSelectedValue("Custom")
  }
  const customizeTextLegend = (item) => {
    return item.seriesName === 'Offline' ? 'Offline' : 'Online';
  }
  
  const handleEndDate = () => {
    dateBoxRefEndEvent.current.instance.open()
    setSelectedValue("Custom")
  }

  const handleStartDateChange = async (value) => {
    if (moment(value).isAfter(currentDate)) {
      await setStartDate(currentDate);
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(currentDate).format());
    } else {
      await setStartDate(value);
      if (value && endDate && moment(value).isAfter(endDate)) {
        await setEndDate(moment(value).add(1, 'days').toDate());
        localStorage.setItem(`${tenant}dateFilterTo`, moment(value).add(1, 'days').toDate())
      }
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).format())

      if (endDate) {
        localStorage.setItem(`${tenant}dateFilterTo`, moment(endDate).format());
      }
  }
    getData();
  };

  const handleEndDateChange = async (value) => {
    if (moment(value).isAfter(currentDate)) {
      await setEndDate(currentDate);
      localStorage.setItem(`${tenant}dateFilterTo`, moment(currentDate).format());
    } else {
      await setEndDate(value);
      if (value && startDate && moment(value).isBefore(startDate)) {
        await setStartDate(moment(value).subtract(1, 'days').toDate());
        localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).subtract(1, 'days').toDate())
      }
      localStorage.setItem(`${tenant}dateFilterTo`, moment(value).format())

      if (startDate) {
        localStorage.setItem(`${tenant}dateFilterFrom`, moment(startDate).format());
      }
  }
    console.log('log 133');
    getData();
  };

  const handleValueChange = async (value) => {
    console.log('value: ', value);
    setSelectedValue(value);
    if (value === 'Custom') {
      setIsDisabled(false)
      localStorage.setItem(`${tenant}dateFilterText`, value);
    } else {
      setStartDate(null);
    setEndDate(null);
    let start, end;
    switch (value) {
      case 'Last 24 hours':
        start = moment().subtract(24, 'hours').format();
        end = moment().format();
        break;
      case 'Today':
        start = moment().startOf('day').format();
        end = moment().format();
        break;
      case 'Last 5 min':
        start = moment().subtract(5, 'minutes').format();
        end = moment().format();
        break;
      case 'Last 20 min':
        start = moment().subtract(20, 'minutes').format();
        end = moment().format();
        break;
      case 'Last 1 hour':
        start = moment().subtract(1, 'hour').format();
        end = moment().format();
        break;
      case 'Last 4 hours':
        start = moment().subtract(4, 'hours').format();
        end = moment().format();
        break;
      case 'Last 12 hours':
        start = moment().subtract(12, 'hours').format();
        end = moment().format();
        break;
      case 'Yesterday':
        start = moment().subtract(1, 'day').startOf('day').format();
        end = moment().subtract(1, 'day').endOf('day').format();
        break;
      case 'This Week':
        start = moment().startOf('week').format();
        end = moment().format();  // Change to current time
        break;
      case 'Last Week':
        start = moment().subtract(1, 'weeks').startOf('week').format();
        end = moment().subtract(1, 'weeks').endOf('week').format();
        break;
      case 'This Month':
        start = moment().startOf('month').format();
        end = moment().format();  // Change to current time
        break;
      case 'Last Month':
        start = moment().subtract(1, 'month').startOf('month').format();
        end = moment().subtract(1, 'month').endOf('month').format();
        break;
      // case 'Last 1 day':
      //   start = moment().subtract(1, 'day').format();
      //   end = moment().format();
      //   break;
      case 'Last 7 days':
        start = moment().subtract(7, 'days').format();
        end = moment().format();
        break;
      default:
        break;
      }
      console.log('start: ', start, ',end: ', end);
      setStartDate(start);
      setEndDate(end);
      localStorage.setItem(`${tenant}dateFilterText`, value);
    }
  };

    

  const customizeSeries = (status) => {
    console.log('STATUS: ', status);
    if(status === 'Online') {
      return { color: '#6D6D6D'};
    } else {
      return { color: '#C41C1D'};
    }
  };

  const handleCustomValidation = (e) => {
    if (e.value > currentDate) {
      e.isValid = false;
      e.errorText = ''; // Set the error text to empty to avoid displaying a message
    }
  };

  return (
  <>
  <div id="full-page-content-gateway-detail" className="page-content" style={{overflowY: "scroll"}} ref={downloadTargetRef}>
    <div className='eventscontainer' id="full-page-content-gateway-detail-events-container">
		<div className='pageFullWidthEvents'>
        <div className='btnDateContainerEvents'>
        <SelectBox
              style={{ marginRight: '10px', width: '20%' }}
              dataSource={['Today', 'Last 5 min', 'Last 20 min', 'Last 1 hour', 'Last 4 hours', 'Last 12 hours', 'Last 24 hours', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Last 7 days', 'Custom']}
              value={selectedValue}
              onValueChanged={(e) => handleValueChange(e.value)}
              labelMode="static"
              label="Period"
              stylingMode="outlined"
            />
            <div onClick={() => handleStartDate()}>
              <DateBox
                ref={dateBoxRefStartEvent}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={startDate}
                onValueChanged={async (e) => await handleStartDateChange(e.value)}
                placeholder="Start Date"
                labelMode="static"
                label="Start Date"
                stylingMode="outlined"
                invalidDateMessage=""
                validationMessageMode="always"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
                // max={currentDate}
              />
            </div>
            <div onClick={() => handleEndDate()}>
              <DateBox
                ref={dateBoxRefEndEvent}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={endDate}
                invalidDateMessage=""
                validationMessageMode="always"
                onValueChanged={async (e) => await handleEndDateChange(e.value)}
                placeholder="End Date"
                labelMode="static"
                label="End Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
                // max={currentDate}
              />
            </div>
        </div>
        <div style={{marginTop: "10px", width: "80%"}}>
        <div style={{marginTop: "20px"}}>
          {data.length > 0 ? 
        <Chart animation={{"enabled":false}} dataSource={data} barGroupPadding={0.2} rotated={true}>
          <Size
            width={'100%'}
            height={150}
          />
          <ArgumentAxis categories={['Device Motion']} label={{
              customizeText: (arg) => {
                return ''
              }
            }}
          >
            <Tick visible={false} />
          </ArgumentAxis>
          <ValueAxis
            label={{
              customizeText: (arg) => {
                return moment(arg.value).format("YYYY-MM-DD HH:mm")
              },
            }}
          />
          <CommonSeriesSettings
            type="rangeBar"
            argumentField="activity"
            rangeValue1Field="start"
            rangeValue2Field="end"
            barOverlapGroup="activity"
          >
          </CommonSeriesSettings>
          <Legend verticalAlignment="center" horizontalAlignment="left" customizeText={customizeTextLegend} />
          <SeriesTemplate nameField="status" customizeSeries={customizeSeries} />
        </Chart> : ""}
			</div>
		</div>
	</div>
      </div>
      <DataGrid
        id="gatewayDetail"
        loadPanel={false}
        noDataText={loadingText}
        keyExpr="id"
        showColumnLines={true}
        showRowLines={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnMinWidth={20}
        columnAutoWidth={true}
        showBorders={true}
        ref={dropDataGridRef}
        onColumnsChanging={function (e) { if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
          setLoadingText('No Data'); }
        }}
        dataSource={data}>
        <Paging enabled={false} />
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('start')}
          caption={"Start"}
          minWidth={100}
          width={220}
          dataType="datetime"
          format="yyyy-MM-dd HH:mm"
          key={'time'}
          fixed={true}
          defaultSortOrder="desc"
        >
        </Column>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('end')}
          caption={"End"}
          minWidth={100}
          width={220}
          dataType="datetime"
          format="yyyy-MM-dd HH:mm"
          key={'time1'}
          fixed={true}
        >   
        </Column>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('status')}
          caption={'Status'}
          visible={true}
          dataType='string'
          key={'status'}
          fixed={true}
          minWidth={50}
          width={120}
        >
        </Column>
      </DataGrid>
      <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      </div>
    </>
  );
}