import React, { useState, useEffect, useRef } from 'react';
import TreeList, {
  RemoteOperations, Column, Paging, Editing, Form, Texts, Popup as Popup2, Button, StateStoring
} from 'devextreme-react/tree-list';
import { Item, Label } from 'devextreme-react/form';
import TenantService from "../../api/tenant.service";
import { Popup, Position } from 'devextreme-react/popup';
import SpacesService from '../../api/spaces.service';
import FloorplanPopup from '../components/floorplan'
import AssetsService from "../../api/assets.service";
import './spaces.css'
import Toast from 'components/Toast';
import * as DOMPurify from 'dompurify';
import Test from "../test"
import { useHistory } from "react-router";
import generatePDF, { Margin } from 'react-to-pdf';
import moment from 'moment-timezone';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';

export default function App() {
  const downloadTargetRef = useRef();
  const [popupVisible, setPopupVisible] = useState(false)
  const [spaceId, setSpaceId] = useState("")
  const [renderFloorplan, setRenderFloorplan] = useState(false)
  const [getAnchors, setGetAnchors] = useState(true)
  const [getGateways, setGetGateways] = useState(true)
  const [className, setClassName] = useState("")
  const [title, setTitle] = useState("")
  const [type, setType] = useState("")
  const [data, setData] = useState([])
  const [headData, setHeadData] = useState([])
  const [models, setModels] = useState([])
  const [types, setTypes] = useState([])
  const [toggleEditBool, setToggleEditBool] = useState(false)
  const [uploadPopupVisible, setUploadPopupVisible] = useState(false)
  const [uploadSpaceId, setUploadSpaceId] = useState("")
  const [zoneEditControl, setZoneEditControl]=useState(false);
  const [heightOfGrid, setHeightOfGrid] = useState('calc(100vh - 51px)');
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 2000,
  });
  const [editAllowed, setEditAllowed] = useState(false)
  const [loadingText, setLoadingText] = useState('Loading...');
  const history = useHistory();
  useEffect(() => {
    if (history.location.state) {
      setToastConfig({
        isVisible: history.location.state.isVisible,
        type: history.location.state.type,
        message: history.location.state.message
      })
    }
  }, [history])
  
  const downloadReport = async() => {
    setLoading(true);
    await setHeightOfGrid('100%');
    await generatePDF(downloadTargetRef, {
      filename: `spaces-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`,
      page: { margin: Margin.LARGE }
    });
    await setHeightOfGrid('calc(100vh - 51px)');
    setLoading(false);
  }

  function checkRole(){
    let spaceConfig = false
      if(window.location.href.includes("config/space")){
        spaceConfig = true
        setClassName("config-space-custom")
      } else {
      }
      var role = JSON.parse(localStorage.getItem("role"));
          let canEdit = false
          for(let i=0; i<role.length; i++){
              if(role[i].name === "Asset Attributes"){
                  if(role[i].write === true){
                      canEdit = true
                  }
                  
              }
          }
          if(canEdit && spaceConfig){
              setEditAllowed(true)
              setToggleEditBool(true)
          }
  }

  const tenant = TenantService.getTenant()

  function getData(){
    SpacesService.getSpaces(tenant).then((value) => {
      let response=value.data
      let myData = [{id: null, name: "Unparented"}]
      for(let i = 0;i<response.data.length;i++){
        myData[i+1] = response.data[i]
      }
      setHeadData(myData)
      setData(response.data)
      if (response.data.length === 0){
        setLoadingText("No Data")
      }
    })
  }

  function assetsText(e){
    if(e.data.hasFloorplan){
      return (
        <>
          View
        </>
      )
    }
  }

  function gatewaysText(e){
    if(e.data.floorplan !== null){
      if(editAllowed){
        return(
          <>
            Edit
          </>
        )
      } else {
        return(
          <>
            View
          </>
        )
      }
      
    }
  }

  function anchorsText(e){
    if(e.data.floorplan !== null){
      if(editAllowed){
        return(
          <>
            Edit
          </>
        )
      } else {
        return(
          <>
            View
          </>
        )
      }
      
    }
    
  }

  function floorplanText(e){
    if(e.data.hasFloorplan){
      if(toggleEditBool === true || toggleEditBool === "true"){
        if(e.data.operationsAllowed !== undefined){
          if(e.data.operationsAllowed.updateZone){
            return(
              <>
                Edit
              </>
            )
          }
        }
        
      } else {
        return(
          <>
            View
          </>
        )
      }
    }
    
  }

  function uploadText(e){
    
    if(e.data.operationsAllowed !== undefined){
      if(e.data.operationsAllowed.uploadFloorplan)
        return(
          <>
            Edit
          </>
        )
    } else {
    }
    
  }

  function buttonColor(e) {
    if((e.column.caption === 'Floorplan') && e.rowType !== "header"){
        if(e.data.hasFloorplan && (toggleEditBool === false || toggleEditBool === "false" || toggleEditBool === null)){
            e.cellElement.style.color = "#C5260C";
            e.cellElement.style.setProperty('cursor', 'pointer', 'important')
      }
    }
    if(toggleEditBool === true || toggleEditBool === "true"){
      if((e.column.caption === 'Zone') && e.rowType !== "header"){
        if(e.data.operationsAllowed !== undefined){
          if(e.data.operationsAllowed.updateZone && e.data.hasFloorplan){
            e.cellElement.style.color = "#C5260C";
            e.cellElement.style.setProperty('cursor', 'pointer', 'important')
          }
        }
      }
    }
    if(e.column.caption === 'Assets'  && e.rowType !== "header"){
      if(e.data.hasFloorplan){
        
        e.cellElement.style.color = "#C5260C";
        e.cellElement.style.setProperty('cursor', 'pointer', 'important')
    }
  }
    if(e.column.caption === 'Anchors'  && e.rowType !== "header"){
        if(e.data.floorplan !== null){
          
          e.cellElement.style.color = "#C5260C";
          e.cellElement.style.setProperty('cursor', 'pointer', 'important')
      }
    }
    if((e.column.caption === 'Floorplan' && (toggleEditBool === true || toggleEditBool === "true")) && e.rowType !== "header"){
      if(e.data.operationsAllowed !== undefined){
        if(e.data.operationsAllowed.uploadFloorplan){
          
          e.cellElement.style.color = "#C5260C";
          e.cellElement.style.setProperty('cursor', 'pointer', 'important')
        }
      }
  }
    if(e.column.caption === 'Gateways' && e.rowType !== "header"){
      if(e.data.floorplan !== null){
        e.cellElement.style.color = "#C5260C";
        e.cellElement.style.setProperty('cursor', 'pointer', 'important')
    }
    }
  }

  useEffect(() => {
    if (uploadPopupVisible === true){
      history.push({
        pathname:  `/upload`,
        state: { 
          spaceId: uploadSpaceId, 
          toggle: null, 
          showDelete: true
        }
     })
     setUploadPopupVisible(false)
    }
    
  }, [uploadPopupVisible]);

  function showInfo2(e) {
    if(e.row !== undefined){
      if(toggleEditBool === true || toggleEditBool === "true"){
        if(e.data.operationsAllowed !== undefined){
          if(e.row.data.operationsAllowed.uploadFloorplan && e.column.caption === "Floorplan"){
            setUploadSpaceId(e.row.data.id)
            setUploadPopupVisible(true)
          }
          if(e.row.data.hasFloorplan && e.column.caption === "Zone"){
            if(e.data.operationsAllowed.updateZone){
              setZoneEditControl(true)
              setSpaceId(e.row.data.id)
              setRenderFloorplan(true)
              setGetAnchors(false)
              setGetGateways(false)
              setTitle(`Zone Edit - ${e.data.name}`)
              setType('polygonEdit')
              setPopupVisible(true)
            }
          }
        }


      } else {
        if(e.row.data.hasFloorplan && e.column.caption === "Floorplan"){
          setSpaceId(e.row.data.id)
          setRenderFloorplan(true)
          setGetAnchors(false)
          setGetGateways(false)
          setTitle(`Floorplan - ${e.data.name}`)
          setType('polygon')
          setPopupVisible(true)
        }

      }


      if(e.row.data.floorplan !== null && e.column.caption === "Anchors"){
        setSpaceId(e.row.data.id)
        setRenderFloorplan(true)
        setGetAnchors(true)
        setGetGateways(false)
        setTitle(`Anchors - ${e.data.name}`)
        setType('anchors')
        setPopupVisible(true)
      }
      if(e.row.data.floorplan !== null && e.column.caption === "Gateways"){
        setSpaceId(e.row.data.id)
        setRenderFloorplan(true)
        setGetAnchors(false)
        setGetGateways(true)
        setTitle(`Gateways - ${e.data.name}`)
        setType('gateways')
        setPopupVisible(true)
      }
      if(e.row.data.hasFloorplan && e.column.caption === "Assets"){
        let id = e.data.id
        AssetsService.getAssetsInSpace(id).then((res) => {
          
          setSpaceId(e.row.data.id)
          setRenderFloorplan(true)
          setGetAnchors(false)
          setGetGateways(false)
          setTitle(`Assets - ${e.data.name}`)
          setType('assets')
          setPopupVisible(true)
        })
    }


    
      
    }
  }

  function hideInfo() {
    setPopupVisible(false)
    setRenderFloorplan(false)
    setUploadPopupVisible(false)
    getData()
  }

  function onRowUpdating(e) {
    const id = e.oldData.id
    let name
    let model
    let type
    let parent

    if(e.newData.name !== undefined){
      name = e.newData.name
    } 
    if(e.newData.model !== undefined){
      model = e.newData.model.id
    }
    if(e.newData.type !== undefined){
      type = e.newData.type.id
    }
    if(e.newData.headId !== undefined){
      parent = e.newData.headId
    }

    SpacesService.editSpace(id, name, parent, type, model).then((res) => {
      getData()
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: res.status===200 ? 'success' : 'error',
        status: res?.data?.status ? res.data.status : res?.status,
        message: res?.data?.message ? res.data.message : res?.message,
      });
    })
  }

  function onRowInserted(e) {
    let headId = null
    if(e.data.headId !== 0){
      headId = e.data.headId
    }
     
    SpacesService.addSpace(e.data.name, headId, e.data.type.id, e.data.model.id).then((res) => {
      getData()
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: res.status===200 ? 'success' : 'error',
        status: res?.data?.status ? res.data.status : res?.status,
        message: res?.data?.message ? res.data.message : res?.message,
      });
    })


  }

  function onRowRemoved(e) {
    
    try {
      SpacesService.deleteSpace(e.data.id).then((res) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: res.status===200 ? 'success' : 'error',
          status: res?.data?.status ? res.data.status : res?.status,
          message: res?.data?.message ? res.data.message : res?.response?.data?.message,
        });
        getData()
      })
      
    } catch (error) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'error',
        status: 500,
        message: error,
      });
      
    }
    
  }

  function getModels() {
    SpacesService.getModels(tenant).then((value) => {
      let response=value.data
      setModels(response.data)
    })
  }


  function getTypes() {
    SpacesService.getSpaceTypes(tenant).then((value) => {
      let response=value.data
      let names = []
      let namesId = []
      for (let i=0; i<response.data.length; i++){
        names.push({
          name: response.data[i].name,
          id: response.data[i].id
        })
         namesId.push(response.data[i].id)


      }
      setTypes(names)
    })
  }

  useEffect(() => {
  }, [toggleEditBool]);

  const typeEditorOptions = { 
    dataSource: types, 
    searchEnabled: true, 
    value: '', 
    displayExpr: "name",
    valueExpr: "id",
    acceptCustomValue: false
  };

   const headIdEditorOptions = { 
    dataSource: headData, 
    searchEnabled: true, 
    value: '', 
    displayExpr: "name",
    valueExpr: "id",
    acceptCustomValue: false
  };

  const modelEditorOptions = {
    dataSource: models, 
    searchEnabled: true, 
    value: '', 
    displayExpr: "name",
    valueExpr: "id",
    acceptCustomValue: false
  }

  function allStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while ( i-- ) {
        values.push( {
          key: keys[i],
          val: localStorage.getItem(keys[i])
        } );
    }
}

useEffect(() => {
  if (popupVisible && type !== undefined){
    let moveMarkers = null
    if(window.location.href.includes("config/space")){
      moveMarkers = true
    }

    history.push({
      pathname:  `/floorplan`,
      state: { 
        spaceId: spaceId,
        myMarkers: getAnchors,
        type: type,
        windowType: {height: '90vh', width: '90vw'},
        toggleEditBool: toggleEditBool,
        title: title,
        moveMarkers: moveMarkers,
        zoneEditControl: zoneEditControl
      }
   })
    
  }
  
}, [popupVisible]);

useEffect(() => {
  if (popupVisible && type !== undefined){
    let moveMarkers = null
    if(window.location.href.includes("config/space")){
      moveMarkers = true
    }

    history.push({
      pathname:  `/floorplan`,
      state: { 
        spaceId: spaceId,
        myMarkers: getAnchors,
        type: type,
        windowType: {height: '90vh', width: '90vw'},
        toggleEditBool: toggleEditBool,
        title: title,
        moveMarkers: moveMarkers
      }
   })
   
  }
  
}, [popupVisible]);

function floorplanPop(){
  if (popupVisible && type !== undefined){
    return (
      <FloorplanPopup spaceId={spaceId} myMarkers={getAnchors} type={type}>
          </FloorplanPopup>
    )
  } else {
    return(<></>)
  }
}

const [testPopup, setTestPopup] = useState(false)

function closeTest(){
  setTestPopup(false)
}



useEffect(() => {
  
}, [getAnchors, type]);

  useEffect(() => {
    checkRole()
    allStorage()
    getModels()
    getTypes()
    getData()
  }, []);

    return (
      <>
      <div id="full-page-content-spaces" className="page-content" style={{overflowY: "scroll"}} ref={downloadTargetRef}>
      <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Spaces</b>
        </h5>
        <Popup
          visible={testPopup}
          onHiding={closeTest}
          dragEnabled={false}
          showCloseButton={true}
          showTitle={true}
          title={"Test Popup"}
          container=".dx-viewport"
        >
          <Position
            at="center"
            my="center"
          />
          <Test></Test>
        </Popup>
        <Toast
          setParentToastConfig={setToastConfig}
          toastBody={toastConfig}
        />
        {renderFloorplan && 
        <Popup
          visible={popupVisible}
          onHiding={hideInfo}
          dragEnabled={false}
          closeOnOutsideClick={setRenderFloorplan}
          showCloseButton={true}
          showTitle={true}
          title={title}
          container=".dx-viewport"
        >
          <Position
            at="center"
            my="center"
          />
          {floorplanPop()}
        </Popup>}
        <TreeList
          style={{marginTop: "10px"}}
          noDataText={loadingText}
          showColumnLines={false}
          showRowLines={true}
          height={heightOfGrid}
          allowColumnResizing={true}
          id="tree-list"
          dataSource={data}
          keyExpr="id"
          parentIdExpr="headId"
          onCellPrepared={buttonColor}
          onCellClick={showInfo2}
          onRowUpdating={onRowUpdating}
          onRowInserted={onRowInserted}
          onRowRemoved={onRowRemoved}
        >
          <Paging enabled={false} />
          <StateStoring enabled={true} type="localStorage" storageKey={tenant+"spaces"}/>
          <Editing
            allowUpdating={toggleEditBool}
            allowDeleting={toggleEditBool}
            allowAdding={toggleEditBool}
            use-icons={ true }
            mode="popup"
            texts={{
              addRow: 'Add Space'
            }}
          >
            <Texts
              confirmDeleteMessage="Are you sure you want to delete this Space?"
            />
            <Popup2
              showTitle={true}
              width={500}
              height={600}
              title="Space"
            />
            <Form colCount={1}>
              <Item dataField="name" isRequired={true}/>
              <Item dataField="model.id" isRequired={true}
                editorType="dxSelectBox" editorOptions={modelEditorOptions}>
                <Label text="Space Mobilty" />
              </Item>
              <Item dataField="type.id" isRequired={true}
                editorType="dxSelectBox" editorOptions={typeEditorOptions}>
                  <Label text="Space Type" />
              </Item>
              <Item dataField="headId" isRequired={false}
                editorType="dxSelectBox" editorOptions={headIdEditorOptions}>
                  <Label text="Parent Space" />
              </Item>
            </Form>
          </Editing>
          <RemoteOperations  />
          <Column
            dataField={DOMPurify.sanitize('name')}
            caption={'Space'}
            width={350}
            fixed={true}
          />
          <Column
            dataField={DOMPurify.sanitize('headId')}
            caption={'headId'}
            visible={false}
            width={350}
            fixed={true}
          />
          <Column
            dataField={DOMPurify.sanitize('model.id')}
            caption={'model.id'}
            visible={false}
            width={350}
            fixed={true}
          />
          <Column
            dataField={DOMPurify.sanitize('type.id')}
            caption={'type.id'}
            visible={false}
            width={350}
            fixed={true}
          />
          <Column
            dataField={DOMPurify.sanitize('type.name')}
            caption={'Type'}
            fixed={true}
          />
          <Column
            caption={(toggleEditBool === true || toggleEditBool === "true") ? 'Zone': 'Floorplan'}
            width={100}
            cellRender={floorplanText}
            fixed={true}
          >
          </Column>
          <Column
            caption={(toggleEditBool === true || toggleEditBool === "true") ?'Floorplan':'Upload'}
            width={100}
            cellRender={uploadText}
            visible={toggleEditBool === true || toggleEditBool === "true"}
            fixed={true}
          ></Column>
          <Column
            caption={'Anchors'}
            width={100}
            cellRender={anchorsText}
            fixed={true}
          >
          </Column>
          <Column
            dataField={'id'}
            caption={'Assets'}
            cellRender={assetsText}
            width={80}
            fixed={true}
          />
          <Column
            caption={'Gateways'}
            cellRender={gatewaysText}
            width={100}
            fixed={true}
          />
          <Column type="buttons" visible={toggleEditBool} width={150}>
            <Button name="add" />
            <Button name="edit" />
            <Button name="delete" />
          </Column>
        </TreeList>
        <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
        <div className={`uploadBtnAssetsReport ${className}`} style={{right: "24px", marginTop: "13px"}}>
          <button id="generatePdfButton" alt="Download Report" title="Download report" type="button" onClick={() => downloadReport()}>
            <img src="../icons/reports.svg" alt="Download Report" />
          </button>
        </div>
        </div>
        </div>
        </div>
      </>
    );
}
