import React, { useState, useEffect } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Texts,
  Button,
  Form,
  HeaderFilter,
  SearchPanel,
  RequiredRule,
  Popup,
  FilterPanel
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import TenantService from "../../api/tenant.service";
import SpacesService from "../../api/spaces.service";
import { useRef } from 'react';
import Toast from 'components/Toast';
import { Button as ReactButton } from 'devextreme-react/button';
 
export default function App() {
  const [data, setData] = useState([])
  const [loadingText, setloadingText] = useState('Loading...');
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 1000
  });
  const tenant = TenantService.getTenant()
  const dropDataGridRef = useRef()
  const [isDisabled, setIsDisabled] = useState(true)
  function getData() {
    SpacesService.getSpaceTypes(tenant).then((value) => {
      let response=value.data
      setData(response.data)
      if (response.data.length === 0){
        setloadingText("No Data")
      }
    })
  }

  function onRowInserted(e){
    SpacesService.addSpaceType(e.data.name).then((space) => {
        getData()
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: space.status===200 ? 'success' : 'error',
          status: space?.data?.status ? space.data.status : space?.status,
          message: space?.data?.message ? space.data.message : space?.message,
        });
      })

  }

  function onRowRemoved(e){
    SpacesService.deleteSpaceType(e.data.id ).then((space) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: space.status===200 ? 'success' : 'error',
          status: space?.data?.status ? space.data.status : space?.status,
          message: space?.data?.message ? space.data.message : space?.message,
        });
        getData()
      })

  }

  function onRowUpdating(e){
    SpacesService.editSpaceType(e.oldData.id, e.newData.name ).then((space) => {
        getData()
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: space.status===200 ? 'success' : 'error', 
          status: space?.data?.status ? space.data.status : space?.status,
          message: space?.data?.message ? space.data.message : space?.message,
        });
      })
  }

  const clearFilters = () => {
    const dataGridInstance = dropDataGridRef.current.instance;
    dataGridInstance.clearFilter();
    setIsDisabled(true)
  }

  const optionChange = (e) => {
    console.log('optionChange 826');
    if (e.fullName.indexOf("filterValue") !== -1 || e.fullName.indexOf("searchPanel") !== -1) {
      if (e.value === null){
        const dataGridInstance = dropDataGridRef.current.instance;
        dataGridInstance.clearFilter();
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }
  }

  useEffect(() => {
    getData()
  }, []);


    return (
      <>
      <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Space Types</b>
        </h5>
        <DataGrid
          onOptionChanged={optionChange}
          ref={dropDataGridRef}
          showColumnLines={false}
          showRowLines={true}
          onRowInserted={onRowInserted}
          onRowRemoved={onRowRemoved}
          onRowUpdating={onRowUpdating}
          allowColumnResizing={true}
          height={'calc(100vh - 102px)'}
          noDataText={loadingText}
          onColumnsChanging={function (e) {
            if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
              setloadingText('No Data');
            }
          }}
          dataSource={data}>
        <Paging enabled={false} />
        <Editing
          texts={{
            addRow: 'Add a Space Type'
          }}
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          use-icons={ true }
          mode="popup"
        >
          <Texts
            confirmDeleteMessage="Are you sure you want to delete this Space Type?"
          />
          <Popup
            showTitle={true}
            width={400}
            height={400}
            title="Space Type"
            dicks={false}
          />
          <Form colCount={1}>
            <Item dataField="name" />
          </Form>
        </Editing>
        <FilterRow visible="true" apply-filter="true" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear filter', filterEnabledHint: ''}}/>
        <Column
          dataField={'name'}
          caption={"Type"}
          width={190}
          hidingPriority={8}
        >
          <RequiredRule />
        </Column>
        
        <Column
          dataField={'type'}
          caption={''}
          allowSorting={false}
          allowFiltering={false}
        >
        </Column>
        
        <Column type="buttons" width={110}>
          <Button name="edit" />
          <Button name="delete" />
          
        </Column>
        </DataGrid>
        <Toast
          setParentToastConfig={setToastConfig}
          toastBody={toastConfig}
        />
        </div>
        </div>
      </>
    );
}
