import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Texts,
  Button,
  Form,
  StateStoring,
  HeaderFilter,
  SearchPanel,
  RequiredRule,
  Popup as Popup2,
  LoadPanel,
  Export,
  Grouping,
  FilterPanel,
  Scrolling
} from 'devextreme-react/data-grid';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
import { Item } from 'devextreme-react/form';
import DataSource from 'devextreme/data/data_source';
import { useHistory } from "react-router";
import { Popup, Position } from 'devextreme-react/popup';
// import { DateRangePicker } from 'react-date-range';
import { addDays, addHours } from 'date-fns';
import { Button as Button2 } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import ScrollView from 'devextreme-react/scroll-view';
import * as DOMPurify from 'dompurify';
import TenantService from 'api/tenant.service';
import AssetsService from 'api/assets.service';
// import socketIO from 'socket.io-client';
import FloorplanPopup from 'pages/components/floorplan'; //TODO: component is getting called from wrong place
import Toast from 'components/Toast';
import Form2 from './axiosUpload.js'; //TODO: change Form2 naming
import './assets.css';
import moment from 'moment-timezone';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import jsPDF from 'jspdf';


const dataSource = new DataSource({
  store: [],
  key: 'id',
  searchMode: 'delay', // Use 'delay' mode for better user experience
  searchTimeout: 800, // Delay in milliseconds
})
dataSource._userData = {edit: false};

export default function Assets(props) {
  const [data, setData] = useState([])
  const [attributes, setAttributes] = useState([])
  const [types, setTypes] = useState([])
  const [devices, setDevices] = useState([])
  const [popupVisibleColumnChooser, setPopupVisibleColumnChooser] = useState(false)
  const [spaceId, setSpaceId] = useState("")
  const [popupVisible, setPopupVisible] = useState(false)
  const [popupVisible2, setPopupVisible2] = useState(false)
  const [uploadPopupVisible, setUploadPopupVisible] = useState(false)
  const [assetCoords, setAssetCoords] = useState([])
  const [spaceName, setSpaceName] = useState("")
  const [btnText, setBtnText] = useState("Select Date Range")
  const [isActive, setActive] = useState(false)
  const [visibleColumnChooser, setVisibleColumnChooser] = useState(true)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [apiInProgress, setApiInProgress] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading assets...');
  const [heightOfGrid, setHeightOfGrid] = useState('calc(100vh - 102px)');
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null)

  function exportGrid(e) {
    const workbook = new Workbook(); 
    const worksheet = workbook.addWorksheet("Assets");
    worksheet.columns = [
          { width: 5 },
          { width: 30 },
          { width: 25 },
          { width: 15 },
          { width: 25 },
          { width: 40 },
        ];
    exportDataGrid({
          component: e.component,
          worksheet,
          keepColumnWidths: false,
          topLeftCell: { row: 2, column: 2 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'data') {
              if (gridCell.column.dataField === 'lastKnownLocation.timestamp') {
                excelCell.value = new Date(excelCell.value).toLocaleString('en-US', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                });
              }
            }
          },
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Assets-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`);
          });
        })
    e.cancel = true; 
  }

  const [state3, setState3] = useState([
    {
      devicesId: [],
      devices: [],
      selectedRow: -1,
    }
  ]);
  const [state2, setState2] = useState([
    {
      startDate: addDays(new Date(), - 7),
      endDate: new Date(),
      key: 'selection',
    }
  ]);
  const tenant = TenantService.getTenant()
  const history = useHistory();
  const dropDataGridRef = useRef();
  const downloadTargetRef = useRef();
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 1000,
  });
  const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token || token === "accessToken") {
    } else {
      return token;
    }
  }

  
  const downloadReport = () => {
    const grid = dropDataGridRef.current.instance;
    const dataSource = grid.getDataSource();
    const allData = dataSource.items();
  
    const columns = grid.getVisibleColumns()
      .filter(col => col.dataField)
      .map(col => ({
        header: col.caption,
        dataField: col.dataField
      }));
  
    const rows = allData.map(row => {
      return columns.map(col => {
        const nestedKeys = col.dataField.split('.');
        let value = row;
        nestedKeys.forEach(key => {
          if (value && typeof value === 'object') {
            value = value[key];
          } else {
            value = undefined;
          }
        });
        if (col.dataField === 'lastKnownLocation.timestamp' && value !== '' && value !== undefined && value !== null) {
          value = new Date(value).toLocaleString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          });
        }
        return value;
      });
    });
  
    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows,
      headStyles: {
        fillColor: [181, 22, 22], // Red color
        textColor: [255, 255, 255] // White text color
      }
    });
    doc.save(`Assets-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
  };

  function clearDate() {
    localStorage.setItem(tenant + "dateFilterFrom", null)
    localStorage.setItem(tenant + "dateFilterTo", null)
    localStorage.setItem(tenant + "dateFilterText", `Select Date Range`)
    getData(false, true, false)
  }

  async function getData(applyD, yes, reload) {
    if (dataSource._userData?.edit) return null;
    let markers = []
    if (props.location !== undefined) {
      if (props.location.state !== undefined) {
        if (props.location.state.markers !== undefined) {
          markers = props.location.state.markers
        }
      }
    }
    let from = null
    let to = null
    let to1 = null
    if (applyD === true) {
      from = state2[0].startDate
      to1 = state2[0].endDate
      to = addDays(to1, 1)
      localStorage.setItem(tenant + "dateFilterFrom", from.toDateString())
      localStorage.setItem(tenant + "dateFilterTo", to.toDateString())
      localStorage.setItem(tenant + "dateFilterText", `${from.toDateString()} - 
      ${to1.toDateString()}`)
      setBtnText(`${from.toDateString()} - 
      ${to1.toDateString()}`)
    } else if (applyD === "inventory") {
      from = addHours(new Date(), -2)
      to = new Date()
      localStorage.setItem(tenant + "dateFilterFrom", from.toDateString())
      localStorage.setItem(tenant + "dateFilterTo", to.toDateString())
      localStorage.setItem(tenant + "dateFilterText", "Inventory")
      setBtnText("Inventory")
    } else if ((localStorage.getItem(tenant + "dateFilterFrom") !== null || localStorage.getItem(tenant + "dateFilterFrom") !== "null") && applyD !== false) {
      from = Date.parse(localStorage.getItem(tenant + "dateFilterFrom"))
      to = Date.parse(localStorage.getItem(tenant + "dateFilterTo"))
      to1 = "test"
      let myBtnText = localStorage.getItem(tenant + "dateFilterText")
      if (myBtnText !== null) {
        setBtnText(localStorage.getItem(tenant + "dateFilterText"))
      }
    }
    else if (applyD === false) {
      from = null
      to = null
      setBtnText("Select Date Range")
    }
    if (yes === true) {
      AssetsService.getAssets(tenant, null, null).then((value) => {
        let assets = value.data.data
        setLoadingText("No Data");
        if (markers.length !== 0) {
          let assetsToShow = assets.filter(asset => {
            return markers.find(marker => {
              return marker === asset.id
            })
          })
          appendAssetsData(assetsToShow);
        } else {
          appendAssetsData(value.data.data);
        }
      })
    } else {
      getAttributes()
      setData([])
    }
  }

  function appendAssetsData(newRecords){
    dataSource.store()._array = newRecords;
    console.log('reload 11');
    dataSource.reload();
  }

  function getAttributes() {
    AssetsService.getTenantAssetTypeAttributes(tenant).then((value) => {
      let response = value?.data
      setAttributes(response?.data)
    })
  }

  // TODO: All these methods needs to change/remove----start>>
  function onEditorPreparing(e) {
    if(e.visible && e.parentType === 'dataRow') {
      dataSource._userData = {edit: true}
    }
  }

  function onEditCanceled() {
    dataSource._userData = {edit: false}
  }

  // useEffect(()=> {
  //   const socketClient = socketIO.connect(`${process.env.REACT_APP_API_URL}`, {forceNew: true, path:"/api/socket/"});
  //   setSocket(socketClient)
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'visible') {
  //       if (!socketClient.connected) {
  //         socketClient.connect();
  //       }
  //     }
  //   };

  //   // Add an event listener for visibility change
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
    
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     socketClient.disconnect();
  //   };


  // },[])

  useEffect(async() => {
    await getAttributes();
    await getTypes();
    await getDevices();
  }, [apiInProgress]);

  // TODO: All these methods needs to change/remove----<<<end

  async function startEdit(index) {
    await dropDataGridRef.current.instance.editRow(index);
  }

  async function editRow(e) {
    await startEdit(e.row.dataIndex);
    getDevices(e.row.data.pairedDevice.serialNumber, e.row.dataIndex)
  }

  function getDevices(currentDevice, row) {
    AssetsService.getAssetTagsToPair(tenant).then((value) => {
      let response = value.data
      let serials = []
      let serialsId = []
      serials.push({
        serialNumber: "Unpaired",
        id: "null"
      })
      serialsId.push(null)
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].freeToPair || response.data[i].serialNumber === currentDevice) {
          serials.push({
            serialNumber: response.data[i].serialNumber,
            id: response.data[i].id
          })
          serialsId.push(response.data[i].id)
        }
      }
      setState3({
        devicesId: serialsId,
        devices: serials,
        selectedRow: row,
      })
      setDevices(serials)
    })
  }

  function getTypes() {
    AssetsService.getAssetTenantTypes(tenant).then((value) => {
      let response = value.data
      let names = []
      let namesId = []
      for (let i = 0; i < response.data.length; i++) {
        names.push({
          name: response.data[i].name,
          id: response.data[i].id
        })
        namesId.push(response.data[i].id)
      }
      setTypes(names)
    })
  }

  function onRowInserting(e) {
    if (e.data.type === undefined) {
    }
  }

  async function onStatusUpdate(e) {
    let assetsLocalStorage = JSON.parse(localStorage.getItem(tenant + "assets"));
    assetsLocalStorage.searchText = e;
    localStorage.setItem(tenant + "assets", JSON.stringify(assetsLocalStorage));
  }

  function onRowInserted(e) {
    let pairedDeviceId = e.data.pairedDevice.id
    if (pairedDeviceId === "null") {
      pairedDeviceId = null
    }
    AssetsService.addAsset(e.data.name, e.data.type.id, tenant, pairedDeviceId).then(async (asset) => {
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: asset.status===200 ? 'success' : 'error',
        status: asset?.data?.status ? asset.data.status : asset?.status,
        message: asset?.data?.message ? asset.data.message : asset?.message,
      });
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    })
  }

  function onRowRemoved(e) {
    AssetsService.deleteAsset(e?.data?.id).then((asset) => {
      // getData(null, true, false);
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: asset.status===200 ? 'success' : 'error',
        status: asset?.data?.status ? asset?.data?.status : asset?.status,
        message: asset?.data?.message ? asset.data.message : asset?.message,
      });
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    })
  }

  function buttonColor(e) {
    if ((e?.column?.dataField === 'lastKnownLocation.name') && e?.rowType !== "header" && e?.data !== undefined) {
      if (e?.data?.lastKnownLocation !== undefined) {
        if (e?.data?.lastKnownLocation?.name !== null) {
          e.cellElement.style.color = "#337ab7";
          e?.cellElement?.style.setProperty('cursor', 'pointer', 'important')
        }
      }
    }
    if ((e?.column?.dataField === 'event') && e?.rowType !== "header" && e?.data !== undefined) {
      if (e?.data?.event !== undefined) {
        if (e?.data?.event !== null) {
          e.cellElement.style.color = "#337ab7";
          e?.cellElement?.style.setProperty('cursor', 'pointer', 'important')
        }
      }
    }
    if ((e?.column?.dataField === 'name') && e?.rowType !== "header") {
      e.cellElement.style.color = "#337ab7";
      e.cellElement.style.setProperty('cursor', 'pointer', 'important')
    }
    if (e.column.dataField === 'status' && e.rowType !== "header") {
      if (e.data !== undefined) {
        if (e.data.status !== undefined) {
          if (e.data.status === 'online') {
            e.cellElement.style.color = "#12DF1C";
          } else if (e.data.status === 'offline') {
            e.cellElement.style.color = "#FF0000";
          } else{
            e.cellElement.style.color = "#FF0000";
          }
        }
      }
    }
  }

  function showInfo(e) {
    if (e.data){
      if (e.column.name === 'name') {
        e.cellElement.style.color = "#337ab7";
        history.push({
          pathname: `/asset/${e.data.id}`,
          state3: {
            response: ""
          }
        })
      }

    
      if (e.column.name === 'lastKnownLocation.name' && e.data.lastKnownLocation?.name !== null) {
        setSpaceName(e.data.lastKnownLocation?.name)
        setAssetCoords({ coords: e.data.lastKnownLocation?.localCoordinates, serialNumber: e.data.name })
        setSpaceId(e.data.lastKnownLocation?.id)
        setPopupVisible(true)
      }
    }
  }

  function onRowUpdating(e) {
    let name
    let typeId
    let deviceId
    // let pairedDeviceInfo
    if (e.newData.name !== undefined) {
      name = e.newData.name
    }
    if (e.newData.type !== undefined) {

      typeId = e.newData.type.id
    }
    if (e.newData.pairedDevice !== undefined) {
      if (e.newData.pairedDevice.id === "null") {
        deviceId = null
      } else {
        deviceId = e.newData.pairedDevice.id
      }

    } else if (e.oldData.pairedDevice !== undefined) {
      deviceId = e.oldData.pairedDevice.id
    } else {
      deviceId = null
    }
    // const updatedInfo = {
    //   id: e.oldData.id,
    //   name: e.newData?.name !== undefined ? e.newData?.name : e.oldData?.name,
    //   type: e.newData?.type !== undefined ? e.newData?.type : e.oldData?.type,
    //   pairedDevice: devices.find(item => item.id === deviceId)
    // }
    AssetsService.editAsset(e.oldData.id, name, typeId, deviceId).then((asset) => {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: asset?.status===200 ? 'success' : 'error',
        status: asset?.data?.status ? asset?.data?.status : asset.status,
        message: asset?.data?.message ? asset.data.message : asset.message,
      });
      // if(asset.status === 200){
      //   socket.emit(`editAsset`, {asset: updatedInfo, socketID: socket.id, tenant: tenant})
      // }
      getData(null, true, false);
    })

  }


  function floorplan() {
    if (popupVisible) {
      return (
        <FloorplanPopup spaceId={spaceId} myMarkers={[{ spaceCoordinates: assetCoords.coords, serialNumber: assetCoords.serialNumber }]} type={'assetsSpace'}>
        </FloorplanPopup>
      )
    } else {
      return (<></>)
    }
  }

  function showAttributes() {
    return (
      attributes.map((attribute, i) =>
        <Column
          key={i}
          dataField={`attributes.${attribute.name}`}
          caption={attribute.display}
          dataType='string'
          fixed={true}
          visible={showColumn(attribute.name)}
        >
        </Column>
      )
    )
  }

  function hideInfo() {
    setPopupVisible(false)
    setPopupVisibleColumnChooser(false)
  }

  const positionEditorOptions = {
    items: types,
    searchEnabled: true,
    value: '',
    acceptCustomValue: false,
    displayExpr: "name",
    valueExpr: "id",
  };

  const deviceEditorOptions = {
    dataSource: devices,
    searchEnabled: true,
    value: '',
    displayExpr: "serialNumber",
    valueExpr: "id",
  };

  useEffect(() => {
    getToken()
    getData(null, true, false);
  }, []);

  // useEffect(() => {
  //   // this is from Redis subscribe mechanism
  //   if (socket === null) return false
  //   socket.on(`editAssetResponseRedis-${tenant}`, (data) => {
  //     var recordIndex = dataSource.store()._array.findIndex(function(item) {
  //       // console.log('ITEM: ', item);
  //       return item.id == data.id;
  //     });
    
  //     if (recordIndex !== -1) {
  //       // console.log('DATA: ', data);
  //       // dataSource.store()._array[recordIndex].lastKnownLocation = data.lastKnownLocation
  //       // dataSource.store()._array[recordIndex].online = data.online
  //       // dataSource.reload();
        
  //       dataSource.store().update({id: data.id}, {
  //         online: data.online,
  //         lastKnownLocation: {
  //           name: data.lastKnownLocation.name,
  //           timestamp: data.lastKnownLocation.timestamp
  //         }
  //       });
  //       dataSource.load()
  //     }
  //   });

  //   // Update record
  //   socket.on(`editAssetResponse-${tenant}`, (data) => {
  //     var recordIndex = dataSource.store()._array.findIndex(function(item) {
  //       return item.id == data.asset.id;
  //     });
    
  //     if (recordIndex !== -1) {
  //       dataSource.store().update({id: data.id}, {
  //         name: data.asset.name,
  //         type: data.asset.type, 
  //         pairedDevice:data.asset.pairedDevice
  //       });
  //       dataSource.load()
  //     }
  //   });

  //   // Add record
  //   socket.on(`addAssetResponse-${tenant}`, (data) => {
  //     dataSource.store()._array.push(data)
  //     dataSource.load();
  //   });

  //   // Remove record
  //   socket.on(`deleteAssetResponse-${tenant}`, (data) => {
  //     var deleteIndex = dataSource.store()._array.findIndex(function(item) {
  //       return item.id == data;
  //     });
  //     dataSource.store()._array.splice(deleteIndex, 1)
  //     dataSource.load();
  //   });
  // }, [socket])


  function onHidingUpload() {
    setUploadPopupVisible(false)
    history.push({
      pathname: `/assets`,
      state3: {
        response: ""
      }
    })
  }

  function uploadClick(e) {
    setUploadPopupVisible(true)
  }

  function onInitialized(e) {
    if (localStorage.getItem("visibleColumnChooser") === "false") {
      setVisibleColumnChooser(false)
    } else if (localStorage.getItem("visibleColumnChooser") === "true") {
      setVisibleColumnChooser(true)
    } else {
      setVisibleColumnChooser(true)
    }
  }

  function columnChooser(e) {
    setVisibleColumnChooser(!visibleColumnChooser)
    setPopupVisibleColumnChooser(!popupVisibleColumnChooser)
    localStorage.setItem("visibleColumnChooser", !visibleColumnChooser)
  }

  function selectColumn(name) {
    var storedArray = JSON.parse(localStorage.getItem(tenant + "visibleCols"));
    if (storedArray === null) {
      storedArray = []
      storedArray.push({
        name: name,
        value: true
      })
      localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray));
    } else {
      let found = false
      for (let i = 0; i < storedArray.length; i++) {
        if (storedArray[i].name === name) {
          storedArray[i].value = !storedArray[i].value
          found = true
        }
      }
      if (!found) {
        storedArray.push({
          name: name,
          value: true
        })
      }
      localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray));
    }
    setForceUpdate(!forceUpdate)
  }

  function chooserColumn() {
    return (
      <>
        <ScrollView width='100%' height='100%'>
          <div className='assets-columnChooserContainer'>
            {
              attributes.map((attribute, i) =>
                <>
                  <div key={i} className='assets-columnCheckbox' onClick={() => { selectColumn(attribute.name) }}>
                    <CheckBox value={showColumn(attribute.name)} disabled={true} />
                    &nbsp; {attribute.display}
                  </div>
                </>
              )
            }
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("pairedDevice.serialNumber") }}>
              <CheckBox value={showColumn("pairedDevice.serialNumber")} disabled={true} />
              &nbsp; Paired Tag
            </div>
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("lastKnownLocation.name") }}>
              <CheckBox value={showColumn("lastKnownLocation.name")} disabled={true} />
              &nbsp; Space
            </div>
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("event") }}>
              <CheckBox value={showColumn("event")} disabled={true} />
              &nbsp; Latest Event
            </div>
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("lastUpdate") }}>
              <CheckBox value={showColumn("lastUpdate")} disabled={true} />
              &nbsp; Location update time
            </div>
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("status") }}>
              <CheckBox value={showColumn("status")} disabled={true} />
              &nbsp; Status
            </div>

            {tenant === 'c5a9b628-49ae-4f7c-9e5a-8909c07c47bb' ? <><div className='assets-columnCheckbox' onClick={() => { selectColumn("motionDuration") }}>
              <CheckBox value={showColumn("motionDuration")} disabled={true} />
              &nbsp; Motion Duration(Last 7 days)
            </div>
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("motionDistance") }}>
              <CheckBox value={showColumn("motionDistance")} disabled={true} />
              &nbsp; Total Travel Distance(Last 7 days)
            </div></> : ""}
          </div>
        </ScrollView>
      </>
    )
  }

  function showColumn(colName) {
    if (localStorage.getItem(tenant + "visibleCols") !== undefined && localStorage.getItem(tenant + "visibleCols") !== null) {
      var storedArray = JSON.parse(localStorage.getItem(tenant + "visibleCols"));
      let found = false
      for (let i = 0; i < storedArray.length; i++) {
        if (storedArray[i].name === colName) {
          found = true
          return storedArray[i].value
        }
      }
      if (!found) {
        storedArray.push({
          name: colName,
          value: false
        })
        localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray));
        return false
      }
    } else {
      var storedArray2 = []
      storedArray2.push({
        name: colName,
        value: false
      })
      localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray2));
      return false
    }
  }

  function addMetres(rowData) {
    return rowData?.motionDistance?.toString() + "m";
  }

  function secondsToText(rowData) {
    const seconds = rowData?.motionDuration
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    // const remainingSeconds = seconds % 60;
    let result = "";
    if (hours > 0) {
      result += hours + " hour" + (hours > 1 ? "s" : "") + " ";
    }
    if (minutes > 0) {
      result += minutes + " minute" + (minutes > 1 ? "s" : "") + " ";
    }
    if (result === '') result = ``
    return result;
  }

  return (
    <>
    <div id="full-page-content-assets" className="page-content" style={{overflowY: "scroll"}} ref={downloadTargetRef}>
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Assets</b>
        </h5>
      <Popup
        visible={popupVisibleColumnChooser}
        onHiding={hideInfo}
        dragEnabled={true}
        maxHeight={400}
        width={250}
        shading={false}
        showCloseButton={true}
        showTitle={true}
        title={"Column Chooser"}
        container=".dx-viewport"
        key={'columnchooser'}
      >
        <Position
          at="right top"
          my="right top"
          offset="-200 160"
        />
        {chooserColumn()}
      </Popup>
      <Popup
        visible={popupVisible}
        onHiding={hideInfo}
        dragEnabled={false}
        showCloseButton={true}
        showTitle={true}
        title={spaceName}
        container=".dx-viewport"
        key={'spacename'}
      >
        <Position
          at="center"
          my="center"
        />
        {floorplan()}
      </Popup>
      <DataGrid
        id="gridContainer"
        noDataText={loadingText}
        showColumnLines={false}
        showRowLines={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode={'nextColumn'}
        columnMinWidth={80}
        columnAutoWidth={true}
        height={heightOfGrid}
        ref={dropDataGridRef}
        onRowInserting={onRowInserting}
        onRowInserted={onRowInserted}
        onRowRemoved={onRowRemoved}
        onCellClick={showInfo}
        onCellPrepared={buttonColor}
        onRowUpdating={onRowUpdating}
        onEditorPreparing={onEditorPreparing}
        onEditCanceled={onEditCanceled}
        onInitialized={onInitialized}
        onExporting={exportGrid}
        dataSource={dataSource}
      >
        <Scrolling mode="virtual" useNative={true}/>

        <LoadPanel enabled={true} key={'loadpanel'} />
        <StateStoring enabled={true} type="localStorage" storageKey={tenant + "assets"} ignoreColumnOptionNames={
          ["dataField", "dataType", "fixed", "fixedPosition",
            "groupIndex", "name", "selectedFilterOperation", "sortIndex", "visible"]
        } key={'statestoring'}/>
        <Grouping autoExpandAll={true} />
        <Export
          enabled={true}
        />
        <Paging enabled={false} key={'paging'}/>
        <Editing
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          use-icons={true}
          mode="popup"
          key={'editing'}
          texts={{
            addRow: 'Add an Asset',
            confirmDeleteMessage:"Are you sure you want to delete this Asset?"
          }}
        >
          <Texts
            confirmDeleteMessage="Are you sure you want to delete this Asset?"
          />
          <Popup2
            showTitle={true}
            width={500}
            maxHeight={600}
            title="Asset"
          />
          <Form colCount={1}>
            <Item dataField="name" />
            <Item dataField="type.id"
              editorType="dxSelectBox" editorOptions={positionEditorOptions}>
            </Item>
            <Item dataField="pairedDevice.id"
              isRequired={true}
              editorType="dxSelectBox" editorOptions={deviceEditorOptions}>
            </Item>
          </Form>
        </Editing>
        <FilterRow visible={true} apply-filter="true" key={'filterRow'}/>
        <HeaderFilter visible={true} key={'headerfilter'}/>
        <SearchPanel visible={true} width="240" placeholder="Search..." onTextChange={onStatusUpdate} key={'searchAssets'} id="assets-list"/>
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear filter', filterEnabledHint: ''}}/>
        <Column
          dataField={DOMPurify.sanitize('name')}
          width={190}
          allowHiding={false}
          dataType='string'
          key={'name'}
          fixed={true}
        >
          <RequiredRule />
        </Column>
        <Column
          showInColumnChooser={false}
          dataField={DOMPurify.sanitize('type.id')}
          visible={false}
          dataType='string'
          key={'typeId'}
          fixed={true}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('type.name')}
          caption={'Type'}
          allowHiding={false}
          dataType='string'
          key={'typeName'}
          fixed={true}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={DOMPurify.sanitize('event')}
          caption={'Latest Event'}
          allowHiding={true}
          dataType='string'
          visible={showColumn('event')}
          key={'event'}
          id={'event'}
          fixed={true}
          allowFiltering={false}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('motionDistance')}
          caption={'Distance Travelled[m] (Last 7 days)'}
          dataType='string'
          visible={showColumn('motionDistance')}
          key={'motionDistance'}
          // calculateDisplayValue={addMetres}
          fixed={true}
          allowHiding={true}
          allowFiltering={false}
        ></Column>
        <Column
          dataField={DOMPurify.sanitize('motionDuration')}
          caption={'Motion Duration(Last 7 days)'}
          dataType='string'
          visible={showColumn('motionDuration')}
          calculateDisplayValue={secondsToText}
          key={'motionDuration'}
          fixed={true}
          allowHiding={true}
          allowFiltering={false}
        ></Column>
        <Column
          dataField={DOMPurify.sanitize('status')}
          caption={'Status'}
          dataType='string'
          visible={showColumn('status')}
          key={'status'}
          fixed={true}
          allowHiding={true}
        ></Column>
        <Column
          dataField={DOMPurify.sanitize('pairedDevice.id')}
          showInColumnChooser={false}
          dataType="string"
          visible={false}
          key={'pairedDeviceId'}
          fixed={true}
        ></Column>
        <Column
          dataField={DOMPurify.sanitize('pairedDevice.serialNumber')}
          caption={'Paired Tag'}
          visible={showColumn('pairedDevice.serialNumber')}
          key={'pairedDevice.serialNumber'}
          dataType="string"
          fixed={true}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('lastKnownLocation.name')}
          caption={'Space'}
          allowHiding={true}
          dataType='string'
          visible={showColumn('lastKnownLocation.name')}
          key={'lastKnownLocationName'}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('lastKnownLocation.timestamp')}
          allowSorting={true}
          allowFiltering={false}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm a"
          caption={'Location update time'}
          visible={showColumn('lastUpdate')}
          key={'lastKnownLocationTimestamp'}
          fixed={true}
        />
        <Column type="buttons" width={110} key={'actionButtons'} fixed={true}>
          <Button name="edit" onClick={editRow} />
          <Button name="delete" />
        </Column>
        {showAttributes()}
      </DataGrid>
      <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
      <div className='uploadBtnAssetsReport upload3'>
        <button id="generatePdfButton" alt="Download Report" title="Download report" type="button" onClick={downloadReport}>
          <img src="../icons/reports.svg" alt="Download Report" />
        </button>
      </div>
      <div className="uploadBtnAssets upload1" title={"Upload assets"} onClick={uploadClick} key={'uploadbtnassetsbtns'}>
        <i className="dx-icon-upload" style={{ "fontSize": "24px" }}></i>
      </div>
      {/* <div className="uploadBtnAssets2 upload2" title={"Choose columns"} onClick={columnChooser} key={'uploadbtnassets2linker'}>
        <i className="dx-icon-columnchooser" style={{ "fontSize": "24px" }}></i>
      </div> */}
      <div className='uploadBtnAssets2 upload2'>
        <button id="columnChooserBtn" alt="Choose columns" title="Choose columns" type="button" onClick={columnChooser}>
          <img src="../icons/columns.svg" alt="Choose columns" />
        </button>
      </div>
      <Popup
        visible={uploadPopupVisible}
        onHiding={onHidingUpload}
        dragEnabled={false}
        height={150}
        width={600}
        showCloseButton={true}
        showTitle={true}
        title="Upload CSV"
        container=".dx-viewport"
        key={'uploadCsv'}
      >
        {Form2()}
      </Popup>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
        key={'toast'}
      />
      </div>
      </div>
    </div>
    </>
  );
}
