import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from "react-router";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Switch, Button } from 'devextreme-react';
import Form, { SimpleItem, Label } from 'devextreme-react/form';
import { Item as AssetTabItem } from 'devextreme-react/form';
import { useLocation } from 'react-router-dom';
import TenantService from "api/tenant.service";
import AssetsService from "api/assets.service";

import Toast from 'components/Toast';
import AssetView from "./asset.js"
// import EventView from "./events.js"
import UtilizationHeatMap from "./utilizationHeatMap";
import MechanicalHeatMap from "./mechanicalHeatMap";
import moment from 'moment-timezone';
import './assetTab.css'

export default function App(props) {
    const tabPanelRef = useRef(null);
    // const location = useLocation();
    // const selectedIndexFromUrl = location.state ? location.state.selectedIndex : 0;
    const form = useRef(null);
    const assetId = props.match.params.assetId;
    const tenant = TenantService.getTenant();
    const [fields, setFields] = useState([]);
    const [fieldData, setFieldData] = useState();
    const [fieldDataOG, setFieldDataOG] = useState();
    const [checkBoxValue, setCheckBoxValue] = useState(false);
    const [types, setTypes] = useState([]);
    const [devices, setDevices] = useState([]);
    const [pageName, setPageName] = useState("");
    const [editAllowed, setEditAllowed] = useState(false);
    const [saveChangedOG, setSaveChangedOG] = useState([]);
    const [saveChanged, setSaveChanged] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [editedFlag, setEditedFlag] = useState(false);
    const [spaces, setSpaces] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [lastPositionUpdateTime, setLastPositionUpdateTime] = useState("");
    const [latestDwellTime, setLatestDwellTime] = useState("");
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 1000,
    });
    const [isPopupVisible, setisPopupVisible] = useState(false)
    const history = useHistory();

    const acceptOptions = {
        text: 'Yes',
        onClick: function(e){
          manageBtn(true, false)
        }
      };
      const rejectOptions = {
        text: 'No',
        onClick: function(e){
          manageBtn(false, false)
        }
      };

      function manageBtn(isNotifiedVal, isPopupVisibleVal){
        wakeUpBtn(isNotifiedVal)
        setisPopupVisible(isPopupVisibleVal)
        setTimeout(() => {
            history.push('/assets')
        }, 1000);
      }

    function checkRole() {
        var role = JSON.parse(localStorage.getItem("role"));
        let canEdit = false
        for (let i = 0; i < role.length; i++) {
            if (role[i].name === "Asset Attributes") {
                if (role[i].write === true) {
                    canEdit = true
                }
            }
        }
        if (canEdit) {
            setEditAllowed(true)
        }
    }

    const renderContent = () => {
        return (
            <>Do you want to receive a notification when the device is ready for retrieval?</>
        );
    };

    function showPopup() {
        setisPopupVisible(true)
    }
 
    function hidePopup() {
        setisPopupVisible(false)
    }

    function getData() {
        AssetsService.getAsset(assetId).then((value) => {
            let data = value.data.data
            setPageName(data.name)
            if (data.info !== null) {
                setCheckBoxValue(data.info.checkInState.value)
            }
            if (data.info !== null) {
                setCheckBoxValue(data.info.checkInState.value)
            }
            if (data.lastKnownLocation && data.lastKnownLocation.timestamp){
                const now = moment().format('YYYY-MM-DD HH:mm:ss');
                const startTime = moment(data.lastKnownLocation.timestamp).format("YYYY-MM-DD HH:mm")
                setLastPositionUpdateTime(moment(data.lastKnownLocation.timestamp).format("YYYY-MM-DD HH:mm"))
                const diff = getDetailedDifference(startTime, now)
                setLatestDwellTime(diff)
            } else {
                setLastPositionUpdateTime("None")
                setLatestDwellTime("None")
            }
            setFieldData(data.attributes)
            setFieldDataOG(data)
            let currentDeviceSerial = data.pairedDevice.serialNumber
            AssetsService.getAssetTagsToPair(tenant).then((value) => {
                let response=value.data
                let serials = []
                let serialsId = []
                serials.push({
                    "serialNumber": "Unpaired",
                    "id": null
                })
                serialsId.push(null)
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].freeToPair || response.data[i].serialNumber === currentDeviceSerial) {
                        serials.push({
                            "serialNumber": response.data[i].serialNumber,
                            "id": response.data[i].id
                        })
                        serialsId.push(response.data[i].id)
                    }
                }
                setDevices(serials)
            })
        })
    }

    function getDetailedDifference(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);
    
        let days = end.diff(start, 'days');
        start.add(days, 'days');
    
        let hours = end.diff(start, 'hours');
        start.add(hours, 'hours');
    
        let minutes = end.diff(start, 'minutes');
    
        let diffString = '';
    
        if (days > 0) {
            diffString += `${days} days`;
        }
    
        if (hours > 0) {
            if (diffString) diffString += ', ';
            if (hours > 1) diffString += `${hours} hours`;
            else diffString += `${hours} hour`;
        }
    
        if (minutes > 0) {
            if (diffString) diffString += ' and ';
            diffString += `${minutes} minutes`;
        }
    
        return diffString || '0 minutes';
    }
    

    function getTypes() {
        AssetsService.getAssetTenantTypes(tenant).then((value) => {
            let response=value.data
            setTypes(response.data)
        })
    }

    const wakeUpBtn =(isNotification)=>{
        AssetsService.sendCommand(assetId, "WAKEUP", isNotification, isNotification).then((response) => {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response?.data?.status ? response.data.status : response?.status,
                message: response?.data?.message ? response.data.message : response?.message,
            });
        })
    }

    function beepBtn() {
        AssetsService.sendCommand(assetId, "RETRIEVE").then(async (response) => {
            if (response.status === 200) {
                await setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: response.status===200 ? 'success' : 'error',
                    status: response?.data?.status ? response.data.status : response?.status,
                    message: response?.data?.message ? response.data.message : response?.message,
                });
            } else {
                await setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    status: 400,
                    message: 'Cannot retrieve asset because no device paired to asset.',
                });
            }
        }).catch((err)=>{
            console.error('Error:', err);
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 400,
                message: 'Cannot retrieve asset because no device paired to asset.',
            });
        })
    }

    const onValueChanged = useCallback(async (event) => {
        await setCheckBoxValue(!checkBoxValue);
        await AssetsService.sendCommand(assetId, "CHECKIN", !checkBoxValue , !checkBoxValue).then((response) => {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response?.data?.status ? response.data.status : response?.status,
                message: response?.data?.message ? response.data.message : response?.message,
            });
            if(response.status === 200) setCheckBoxValue(!checkBoxValue);
        })
    }, [checkBoxValue]);

    function getTypeAttributes(typeId) {
        AssetsService.getAssetTypeAttributes(typeId).then((value) => {
            let response=value.data
            setFields(response.data)
            setFieldData([])
        })
    }

    function editAsset(key, value) {
        let id = assetId
        let name
        let typeId
        let pairedDevice
        let attributes
        if (key === "name") {
            name = value
        } else if (key === "type.id") {
            typeId = value
            getTypeAttributes(typeId)
        } else if (key === "pairedDevice.id") {
            pairedDevice = value
        } else {
            var dynamicKey = key;
            let payload = {}
            payload[dynamicKey] = value
            attributes = payload
        }
        AssetsService.editAsset(id, name, typeId, pairedDevice, attributes).then((value) => {
            if (value.status === 200) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'success',
                    message: "Edit successful",
                    status: 200,
                });
            } else {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    message: "Edit unsuccessful",
                    status: 500,
                });
            }
        })
    }

    function onFieldDataChanged(e) {
        let existingData = saveChanged
        let didExist = false
        for (let i = 0; i < existingData.length; i++) {
            if (existingData[i].key === e.dataField) {
                existingData[i].value = e.value
                didExist = true
            }
        }
        if (!didExist) {
            existingData.push({
                "key": e.dataField,
                "value": e.value
            })
            setSaveChanged(existingData)
        } else {
            setSaveChanged(existingData)
        }
        setEditedFlag(true)
    }

    function onFieldDataChangedOG(e) {
        let existingDataOG = saveChangedOG
        let didExist = false
        for (let i = 0; i < existingDataOG.length; i++) {
            if (existingDataOG[i].key === e.dataField) {
                existingDataOG[i].value = e.value
                didExist = true
            }
        }
        if (!didExist) {
            existingDataOG.push({
                "key": e.dataField,
                "value": e.value
            })
            setSaveChanged(existingDataOG)
        } else {
            setSaveChangedOG(existingDataOG)
        }
        setEditedFlag(true)
    }

    function onClick(e) {
        for (let i = 0; i < saveChangedOG.length; i++) {
            editAsset(saveChangedOG[i].key, saveChangedOG[i].value)
        }
        for (let i = 0; i < saveChanged.length; i++) {
            editAsset(saveChanged[i].key, saveChanged[i].value)
        }
        setEditedFlag(false)
    }

    const typeEditorOptions = {
        dataSource: types,
        searchEnabled: true,
        displayExpr: "name",
        valueExpr: "id",
    };

    const deviceEditorOptions = {
        dataSource: devices,
        searchEnabled: true,
        displayExpr: "serialNumber",
        valueExpr: "id"
    };

    function goBack() {
        history.push({
            pathname: `/assets/`,
            state: {
                response: ""
            }
        })
    }

    function saveGoBack() {
        onClick()
        history.push({
            pathname: `/assets/`,
            state: {
                response: ""
            }
        })
    }

    useEffect(() => {
        getData()
        getTypes()
        checkRole()
    }, []);

    useEffect(() => {
    }, [types]);

    // useEffect(() => {
    //     // Set the selectedIndex when the component mounts
    //     tabPanelRef.current.instance.option('selectedIndex', selectedIndexFromUrl);
    //   }, [selectedIndexFromUrl]);

    // function onHiding() {
    //     setToastConfig({
    //         ...toastConfig,
    //         isVisible: false,
    //     });
    // }

    function hideInfo() {
        setPopupVisible(false)
    }

    function onSave() {
        if (editedFlag) {
            setPopupVisible(true)
        } else {
            goBack()
        }
    }

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={false}
                title={"Do you wish to save the changes? "}
                container=".dx-viewport"
                height={"120px"}
                width={"270px"}
            >
                <Position
                    at="center"
                    my="center"
                />
                <div className='assetTab-saveDiscardTitle'> Do you wish to save the changes? </div>
                <div className='assetTab-saveDiscardContainer'>
                    <div className='assetTab-saveBtn' onClick={saveGoBack}> SAVE </div>
                    <div className='assetTab-saveBtn' onClick={goBack}> DISCARD </div>
                </div>
            </Popup>
            <div className='topBarAssetTab'>
                <div className='title' >
                     Asset: {pageName} &nbsp;&nbsp;<span style={{paddingLeft: "12px" ,fontSize: "14px"}}>Last Position Update: {lastPositionUpdateTime}</span>&nbsp;&nbsp;<span style={{paddingLeft: "12px" ,fontSize: "14px"}}>Dwell time in current space: {latestDwellTime}</span>
                </div>
                <div className='backBtn' onClick={onSave}>
                    <i className="dx-icon-close"></i>
                </div>
            </div>
            <TabPanel ref={tabPanelRef}
            selectedIndex={selectedTabIndex}
            onOptionChanged={(e) => {
              if (e.name === "selectedIndex") {
                setSelectedTabIndex(e.value);
              }
            }}>
                <Item title="History" >
                    <AssetView 
                        assetId={assetId}
                        onSpaceLoad={(spaces)=>setSpaces(spaces)}
                        onDateChange={(dateRange)=>setDateRange(dateRange)}
                        dateRange={dateRange}
                        selectedTabIndex={selectedTabIndex}
                    />
                </Item>
                <Item title="Edit" >
                    <div className='assetForm'>
                        <div className='assetTab-topForm'>
                            <Form
                                onFieldDataChanged={onFieldDataChangedOG}
                                formData={fieldDataOG}
                                labelLocation={"left"}
                                labelMode={"outside"}
                            >
                                <SimpleItem dataField="name" />
                                <AssetTabItem dataField="type.id"
                                    editorType="dxSelectBox" editorOptions={typeEditorOptions}>
                                    <Label text="Type" />
                                </AssetTabItem>
                                <AssetTabItem dataField="pairedDevice.id"
                                    editorType="dxSelectBox" editorOptions={deviceEditorOptions}>
                                    <Label text="Paired Tag" />
                                </AssetTabItem>
                            </Form>
                        </div>
                        <div className='assetTab-bottomForm'>
                            <h2 className='assetTabh2'> Attributes</h2>
                            <Form
                                onFieldDataChanged={onFieldDataChanged}
                                formData={fieldData}
                                ref={form}
                                disabled={!editAllowed}
                                labelLocation={"left"}
                                labelMode={"outside"}
                            >
                                {fields.map((field, i) =>
                                    <SimpleItem
                                        key={i}
                                        dataField={`${field.name}`}
                                    >
                                    </SimpleItem>
                                )}
                            </Form>
                            {/* <div style={{cursor: "pointer"}} className='profile-save' onClick={onClick}>
                                Save
                            </div> */}
                            <div style={{marginTop: "40px"}}>
                            <Button
                              width={"160px"}
                              type="normal"
                              stylingMode="outlined"
                              onClick={onClick}
                              text="Save"
                            />
                            </div>
                        </div>
                    </div>
                </Item>
                <Item title="Actions">
                    <div className='assetTab-checkboxContainer'>
                        <div className='assetTab-checkboxText'>
                            Asset Checked &nbsp;  &nbsp; OUT
                        </div>
                        <div className='assetTab-checkbox'>
                            <Switch
                                value={checkBoxValue}
                                onValueChanged={onValueChanged}
                                text={"Asset checked"}
                                iconSize={39}
                                switchedOnText={"On"}
                            />
                        </div>
                        <div className='assetTab-checkboxText'>
                            IN
                        </div>
                    </div>
                    <div className='ActionsPage'>
                        <Button
                            height={41}
                            className={'wakeUpBtn'}
                            text="Text"
                            type="normal"
                            stylingMode="outlined"
                            onClick={() => wakeUpBtn(true)}
                        >
                            Wake Up
                        </Button>

                        <Button
                            height={41}
                            className={'beepBtn'}
                            text="Text"
                            type="normal"
                            stylingMode="outlined"
                            onClick={beepBtn}
                            style={{marginLeft:"10px"}}
                        >
                            Retrieve
                        </Button>

                        <Popup
                            title="Confirm"
                            showTitle={true}
                            visible={isPopupVisible}
                            contentRender={renderContent}
                            onHiding={hidePopup}
                            hideOnOutsideClick={false}
                            showCloseButton={true}
                            width={300}
                            height={"auto"}
                        >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="before"
                            options={acceptOptions}
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={rejectOptions}
                        />
                        </Popup>
                    </div>
                </Item>
                {/* <Item title="Events">
                    <EventView assetId={assetId}>
                    </EventView>
                </Item> */}
                <Item title="Utilization">
                    <UtilizationHeatMap
                        assetId={assetId}
                        assetName={pageName}
                        spaces={spaces}
                        tenant={tenant}
                        dateRange={dateRange}
                        onDateChange={(dateRange)=>setDateRange(dateRange)}
                        selectedTabIndex={selectedTabIndex}
                    />
                </Item>
                {/* <Item title="Mechanical">
                    <MechanicalHeatMap
                        assetId={assetId}
                        spaces={spaces}
                        tenant={tenant}
                        dateRange={dateRange}
                        onDateChange={(dateRange)=>setDateRange(dateRange)}
                    />
                </Item> */}
            </TabPanel>
            <Toast
                setParentToastConfig={setToastConfig}
                toastBody={toastConfig}
            />
        </>
    );
}