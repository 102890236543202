import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addAssetType(name, tenantId) {
    return Axios.post(
      "/api/asset/type",
      {
        name: name,
        tenantId: tenantId,
      }
    );
  },

  editAssetType(name, id) {
    return Axios.post(
      "/api/asset/type/edit",
      {
        name: name,
        id: id,
      }
    );
  },

  deleteAssetType(id) {
    return Axios.del(
      "/api/asset/type",
      {
        id: id,
      }
    );
  },
  
  getTenantAssetTypeAttributes(tenantId){
    return Axios.post(
      "/api/asset/attribute",
      {
        tenantId:tenantId,
      } 
    )
  }, 

   getAssetTypeAttributes(typeId) {
    return Axios.post(
      "/api/asset/attribute/type",
    {
      assetTypeId:typeId,
    })
  },  

  addAsset(name, typeId, tenantId, pairedDeviceId) {
    return Axios.post(
      "/api/asset",
      {
        name: name,
        typeId: typeId,
        tenantId: tenantId,
        pairedDeviceId: pairedDeviceId
      }
    );
  },
  editAsset(id, name, typeId, pairedDevice, attributes) {
    return Axios.post(
      "/api/asset/edit",
      {
        id: id,
        name: name,
        typeId: typeId,
        pairedDeviceId: pairedDevice,
        attributes: attributes,
      }
    );
  },

  getAssetEvents(assetId) {
   return Axios.post(
    "/api/asset/events",
    {
      assetId: assetId
    }
  );
  },

  locationHistory(id, from, to, space, page) {
    return Axios.post(
      "/api/asset/location-history",
      {
        id: id,
        from: from,
        to: to,
        spaceId: space,
        page: page
      }
    );
  },

  sendCommand(id, command, value, isNotified = false) {
    return Axios.post(
      "/api/asset/command",
      {
        id: id,
        command: {
          action: command,
          value: value
        },
        isNotified: isNotified
      }
    );
  },

  deleteAsset(id) {
    return Axios.del("/api/asset",
    {
      id:id,
    });
  },

  addAssetToShipment(id, asset_group_id) {
    return Axios.post(
      "/api/assets/attach-shipment",
      {
        id: id,
        asset_group_id: asset_group_id //Can be null
      }
    );
  },

  removeAssetFromShipment(id, asset_group_id) {
    return Axios.post(
      "/api/assets/detach-shipment",
      {
        id: id,
        asset_group_id: asset_group_id
      }
    );
  },

  attachDeviceToAsset(id, device_id) {
    return Axios.post(
      "/api/assets/attach-device",
      {
        id: id,
        device_id: device_id
      }
    );
  },

  getAssetTagsToPair(tenantId) {
    return Axios.post("/api/asset/tags",
    {
      tenantId:tenantId,
    });
  },

  getAssetTenantTypes(tenantId) {
    return Axios.post("/api/asset/tenant/type",
    {
      tenantId:tenantId,
    });
  },
  getAssetTenantAttributeTypes() {
    return Axios.get("/api/asset/tenant/type");
  },

 getAssets2(tenantId) {
   return Axios.post(
    "/api/asset/tenant",
    {
      tenantId:tenantId
    }
  )
}, 
  getAssets(tenantId, from, to) {
    let from2 = from
    let to2 = to
    if (from === undefined || to === undefined) {
      from2 = null;
      to2 = null;
    }
    return Axios.post(
      "/api/asset/assets",
      {
        tenantId: tenantId,
        from: from2,
        to: to2
      }
    );
  },

  getAsset(assetId) {
    return Axios.post(
      `/api/asset/asset-by-id`,
      {
        assetId: assetId
      }
    );
  },

  getAssetsInGroup(assetGroupID) {
    return Axios.get(`/api/assets/group/${assetGroupID}`);
  },

  getAssetsNotInGroup(asset_group_id, tenantID) {
    return Axios.post(
      "/api/assets/not-in-group",
      {
        asset_group_id: asset_group_id,
        tenant_id: tenantID
      }
    );
  },

  getAssetHistory(assetId) {
    return Axios.get(`/api/assets/history/${assetId}`);
  },

  getAssetsInVehicle(vehicleId) {
    return Axios.get(`/api/assets/vehicle/${vehicleId}`);
  },

  getUnpairedDevices(tenantID) {
    return Axios.get(`/api/devices/unpaired/${tenantID}`);
  },

  getAssetsInSpace(spaceId) {
    return Axios.post(
      `/api/asset/space`,
      {
        spaceId: spaceId
      }
    );
  },

  getAssetUtilizationHeatMap(id, spaceId, fromDate, toDate, isMotion, isStationary) { // assets utilization in assets tab
    return Axios.post(
      "/api/asset/utilization/heat-map",
      {
        id: id,
        spaceId: spaceId,
        isMotion: isMotion,
        isStationary: isStationary,
        from: fromDate,
        to: toDate,
      }
    );
  },

  getAssetUtilizationChart(id, spaceId, fromDate, toDate, isMotion, isStationary) { // assets utilization in assets tab
    return Axios.post(
      "/api/asset/utilization/chart",
      {
        id: id,
        spaceId: spaceId,
        isMotion: isMotion,
        isStationary: isStationary,
        from: fromDate,
        to: toDate,
      }
    );
  },

  getAssetMechanicalUtilization(id, spaceId, fromDate, toDate) { // assets utilization in assets tab
    return Axios.post(
      "/api/asset/mechanical",
      {
        id: id,
        spaceId: spaceId,
        from: fromDate,
        to: toDate,
      }
    );
  },

  editAssetAttributeType(id, newName, data) {
    return Axios.post(
      "/api/asset/attribute/edit",
      {
        id: id,
        newName: newName,
        data: data
      }
    );
  },

  deleteAssetAttributeType( id, name ) {
    return Axios.del(
      "/api/asset/attribute",
      {
        id: id,
        name: name
      }
    );
  },
  createAssetAttributeType( name ) {
    return Axios.post(
      "/api/asset/attribute/create",
      {
        name: name
      }
    );
  },


};