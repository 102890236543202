import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Texts,
  Button,
  Form,
  HeaderFilter,
  SearchPanel,
  RequiredRule,
  Popup,
  FilterPanel
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { useHistory } from "react-router";
import EntityService from "../../api/entity.service";
import Toast from 'components/Toast';
import {
  Button as SelectButton
} from 'devextreme-react';

export default function App() {
  const [data, setData] = useState([])
  const [loadingText, setloadingText] = useState('Loading...');
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 600
  });
  const history = useHistory();
  const dropDataGridRef = useRef()
  const [isDisabled, setIsDisabled] = useState(true)
  function getData() {
    EntityService.getTenantEntityGroup().then((value) => {
      setData(value.data.data)
      if (value.data.data.length === 0){
        setloadingText("No Data")
      }
    })
  }

  function onRowInserted(e) {
    EntityService.createEntityGroup(e.data.name).then((res) => {
      getData()
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: res.status===200 ? 'success' : 'error',
        status: res?.data?.status ? res.data.status : res?.status,
        message: res?.data?.message ? res.data.message : res?.message,
      });
    })
  }

  function onRowRemoved(e) {
    EntityService.deleteEntityGroup(e.data.id).then((res) => {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: res.status===200 ? 'success' : 'error',
        status: res?.data?.status ? res.data.status : res?.status,
        message: res?.data?.message ? res.data.message : res?.message,
      });
      getData()
    })
  }

  function onRowUpdating(e) {
    EntityService.editEntityGroup(e.newData.name, e.oldData.id).then((res) => {
      getData()
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: res.status===200 ? 'success' : 'error',
        status: res?.data?.status ? res.data.status : res?.status,
        message: res?.data?.message ? res.data.message : res?.message,
      });
    })
  }

  function buttonColor(e) {
    if (((e.column.caption === 'Entities') || (e.column.caption === 'Rules')) && e.rowType !== "header") {
      e.cellElement.style.color = "#C5260C";
      e.cellElement.style.setProperty('cursor', 'pointer', 'important')
    }
  }

  function showInfo(e) {
    if (e.column.caption === 'Entities') {
      e.cellElement.style.color = "#337ab7";
      history.push({
        pathname: `/behaviors/${e.data.id}`,
        state3: {
          response: ""
        }
      })
    } else if (e.column.caption === 'Rules') {
      e.cellElement.style.color = "#337ab7";
      history.push({
        pathname: `/behaviors/rule/${e.data.id}`,
        state3: {
          response: ""
        }
      })
    }
  }

  function entitiesText() {
    return (
      <>
        Edit
      </>
    )
  }

  function rulesText() {
    return (
      <>
        Edit
      </>
    )
  }


  useEffect(() => {
    getData()
  }, []);

  return (
    <>
    
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Behaviors</b>
        </h5>
      <DataGrid
        noDataText={loadingText}
        ref={dropDataGridRef}
        showColumnLines={false}
        showRowLines={true}
        onRowInserted={onRowInserted}
        onRowRemoved={onRowRemoved}
        onRowUpdating={onRowUpdating}
        onCellClick={showInfo}
        allowColumnResizing={true}
        onCellPrepared={buttonColor}
        height={'calc(100vh - 102px)'}
        onColumnsChanging={function (e) {
          if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
            setloadingText('No Data');
          }
        }}
        dataSource={data}>
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear filter', filterEnabledHint: ''}}/>
        <Paging enabled={false} />
        <Editing
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          use-icons={true}
          mode="popup"
          texts={{
            addRow: 'Add Behavior',
          }}
        >
          <Texts
            confirmDeleteMessage="Are you sure you want to delete this Behavior?"
          />
          <Popup
            showTitle={true}
            width={500}
            maxHeight={800}
            title="Behavior"
          />
          <Form colCount={1}>
            <Item dataField="name" />
          </Form>
        </Editing>
        <FilterRow visible="true" apply-filter="true" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <Column
          dataField={'name'}
          caption={"Behavior Name"}
          hidingPriority={8}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={'Entities'}
          width={100}
          cellRender={entitiesText}
        ></Column>
        <Column
          caption={'Rules'}
          width={100}
          cellRender={rulesText}
        ></Column>
        <Column type="buttons" width={110}>
          <Button name="edit" />
          <Button name="delete" />
        </Column>
      </DataGrid>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      </div>
      </div>
      
    </>
  );
}