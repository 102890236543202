import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Column,
    Paging,
    FilterRow,
    Editing,
    HeaderFilter,
    SearchPanel,
    RequiredRule,
    Selection,
} from 'devextreme-react/data-grid';
import TenantService from "../../api/tenant.service";
import EntityService from "../../api/entity.service";
import AssetsService from "../../api/assets.service";
import DevicesService from "../../api/devices.service";
import GatewayService from '../../api/gateway.service';
import Toast from 'components/Toast';
import { Popup as Popup2 } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import './entityGroup.css'

export default function App(props) {
    const history = useHistory();
    const entityGroupId = props.match.params.entityGroupId
    const [data, setData] = useState([])
    const [listData, setListData] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [popupVisible2, setPopupVisible2] = useState(false)
    const [ruleType, setRuleType] = useState(null)
    const [pageName, setPageName] = useState("")
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 3000
    });
    const tenant = TenantService.getTenant()
    const dropDataGridRef = useRef()

    function getData() {
        EntityService.getEntitiesInGroup(entityGroupId).then((value) => {
            setData(value.data.data.entities)
            setPageName(value.data.data.groupName)
        })
    }

    function onRowInserted(e) {
        EntityService.createEntityGroup(e.data.name).then((res) => {
            getData()
        })
    }

    function onRowRemoved(e) {
        EntityService.deleteEntityGroupMap([e.data.entityId], entityGroupId).then((response) => {
            getData()
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response.status,
                message: response.data.message,
            });
        })
    }

    function onHiding() {
        setListData([])
        setPopupVisible2(false)
        setRuleType("")
        setSelectedItems([])
    }

    function closeTenant(){
        history.push("/config/behaviors");
    }


    function onOptionChanged(e) {
        setSelectedItems(e.selectedRowKeys)
    }

    function createGroupMap() {
        let result1 = selectedItems.map(a => a.id);
        EntityService.createEntityGroupMap(result1, entityGroupId).then((response) => {
            getData()
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response.status,
                message: response.data.message,
            });
        })
        setPopupVisible2(false)
    }

    function addOnClick() {
        setPopupVisible2(true)
    }

    function onRuleChanged(e) {
        setRuleType(e.value)
        if (e.value === 'Gateways') {
            GatewayService.getGateways().then((value) => {
                setListData(value.data.data)
            })
        }
        if (e.value === 'Assets') {
            AssetsService.getAssets(tenant).then((value) => {
                setListData(value.data.data)
            })
        }
        if (e.value === 'Devices') {
            DevicesService.getDevices(tenant).then((value) => {
                let response=value.data
                setListData(response.data)
            })
        }
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            <Popup2
                visible={popupVisible2}
                onHiding={onHiding}
                dragEnabled={false}
                width={700}
                showCloseButton={true}
                showTitle={true}
                title="Select Entities"
                container=".dx-viewport"
            >
                <SelectBox
                    items={['Assets', 'Devices', 'Gateways']}
                    value={ruleType}
                    visible={((ruleType !== 'Assets') && (ruleType !== 'Devices') && (ruleType !== 'Gateways'))}
                    onValueChanged={onRuleChanged}
                    placeholder="Select Entity Type"
                />
                <DataGrid
                    dataSource={listData}
                    height={"90%"}
                    displayExpr={"name"}
                    visible={((ruleType === 'Assets') || (ruleType === 'Devices') || (ruleType === 'Gateways'))}
                    onSelectionChanged={onOptionChanged}
                    allowColumnResizing={true}
                >
                    <Selection
                        mode="multiple"
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />
                    <FilterRow visible={true} />
                    <HeaderFilter visible="showHeaderFilter" />
                    <Paging enabled={false} />
                    <Column dataField="name" visible={(ruleType === 'Assets') || (ruleType === 'Gateways')} />
                    <Column dataField="type.name" visible={(ruleType === 'Assets')} />
                    <Column dataField="lastKnownLocation.name" caption="Space" visible={(ruleType === 'Assets')} />
                    <Column dataField="serialNumber" visible={(ruleType === 'Devices')} />
                    <Column dataField="type" visible={(ruleType === 'Devices')} />
                    <Column dataField="role.name" visible={(ruleType === 'Devices')} />
                    <Column dataField="space.name" visible={(ruleType === 'Devices')} />
                    <Column dataField="status" visible={(ruleType === 'Gateways')} />
                </DataGrid>
                <div className="entityGroup-setEntities" onClick={createGroupMap}>
                    ADD
                </div>
            </Popup2>
            <div className='headerHeadingPage'>
                <div className='pageFullWidthEvents'>
                    <div className='editentities_col'>
                <div style={{ fontSize: "large", display: "inline-block" }}>Entities of Behavior: {pageName}</div>
                <i className="entity-group-add dx-icon-close" onClick={closeTenant}></i>
                <i onClick={addOnClick} className="entity-group-add dx-icon-add" style={{ "fontSize": "24px" }}></i>
            </div>
            <DataGrid
                id="entity-group-map-grid"
                ref={dropDataGridRef}
                showColumnLines={false}
                showRowLines={true}
                onRowInserted={onRowInserted}
                onRowRemoved={onRowRemoved}
                allowColumnResizing={true}
                height={'calc(100vh - 51px)'}
                dataSource={data}>
                <Paging enabled={false} />
                <Editing
                        allowDeleting={true}
                        use-icons={true}
                    >
                    </Editing>
                <FilterRow visible="true" apply-filter="true" />
                <HeaderFilter visible="showHeaderFilter" />
                <SearchPanel visible="true" width="240" placeholder="Search..." />
                <Column
                    dataField={'name'}
                    caption={"Entity Name"}
                >
                    <RequiredRule />
                </Column>
                <Column dataField="type" />
            </DataGrid>
                </div>
            </div>
            
            <Toast
                setParentToastConfig={setToastConfig}
                toastBody={toastConfig}
            />
        </>
    );
}