import React, { useState, useEffect, useMemo } from 'react';
import 'devextreme/data/odata/store';
import SelectBox from 'devextreme-react/select-box';
import DataGrid, {
  Editing,
  Selection,
  Column,
  Scrolling,
  HeaderFilter,
  SearchPanel
} from 'devextreme-react/data-grid';
import { useRef } from 'react';
import TenantService from "../../api/tenant.service";
import NetworkToolService from "../../api/networkTool.service";
import SpacesService from '../../api/spaces.service';
import { Toast } from 'devextreme-react/toast';
import diamondMarker from "../../assets/markers/diamondBlack2.png";
import diamondMarkerRed from "../../assets/markers/diamondRed2.png";
import circle from "../../assets/markers/circle.png";
import greenCircle from "../../assets/markers/greenCircle.png";
import redCircle from "../../assets/markers/redCircle.png";
import { MapContainer, Marker, ImageOverlay } from 'react-leaflet'
import { latLngBounds } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Tooltip, Polyline, Popup as Popup2, Circle } from "react-leaflet";
import { CRS } from 'leaflet';
import "leaflet-draw/dist/leaflet.draw-src.css";
import 'leaflet/dist/leaflet.css'
import './networkTool.css'
import { Popup } from 'devextreme-react';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Legend,
  Tooltip as Tooltip2,
} from 'devextreme-react/chart';
import { Switch } from 'devextreme-react/switch';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default function App() {
  const listRef = useRef();
  const markerRef = useRef(null)
  const messagesEndRef = useRef(null)
  const [tenants, setTenants] = useState([])
  const [validSpace, setValidSpace] = useState([])
  const [mapTags, setMapTags] = useState([])
  const [selectedTagData, setSelectedTagData] = useState()
  const [selectedSAData, setSelectedSAData] = useState()
  const [selectedSAData2, setSelectedSAData2] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const [bounds, setBounds] = useState(new latLngBounds([0, 0], [100, 100]));
  const [center, setCenter] = useState([0, 0])
  const [dataGrid1Visible, setDataGrid1Visible] = useState(false)
  const [dataGrid2Visible, setDataGrid2Visible] = useState(false)
  const [dataGrid3Visible, setDataGrid3Visible] = useState(false)
  const [mapVisible, setMapVisible] = useState(false)
  const [toggleEditBool, setToggleEditBool] = useState(false)
  const [floorplanUrl, setFloorplanUrl] = useState("http://rpo.redlore.com/api/places/floorplan/a32b2298-bff7-44f8-a823-fa6b7fe1b8f7");
  const [show5m, setShow5m] = useState(false)
  const [show1m, setShow1m] = useState(false)
  const [uploadPopupVisible, setUploadPopupVisible] = useState(false)
  const [uploadPopupVisible2, setUploadPopupVisible2] = useState(false)
  const [uploadPopupVisible3, setUploadPopupVisible3] = useState(false)
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedFile2, setSelectedFile2] = React.useState(null);
  const [selectedFileName, setSelectedFileName] = useState("")
  const [csvTagData, setCsvTagData] = useState([]);
  const [csvTagData2, setCsvTagData2] = useState([]);
  const [debugTagData, setDebugTagData] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState([]);
  const [plotData, setPlotData] = useState([])
  // const [bulkExportData, setBulkExportData] = useState([]);
  const [csvExportData, setCsvExportData] = useState([])
  const [anchorLocation, setAnchorLocation] = useState([0, 0])
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [toggleSwitch2, setToggleSwitch2] = useState(false)
  const [axisX, setAxisX] = useState("")
  const [axisY, setAxisY] = useState("")
  const [axisX2, setAxisX2] = useState("")
  const [axisY2, setAxisY2] = useState("")
  const axisLabels = ["avgRmse", "avgLosqi", "avgRssi", "totalScans", "rmseGoodCount", "rmseBadCount", "goodRmsePercent", "distToAnchor", "percentOfTotalRmse"]
  const axisLabels2 = ["error", "losqi", "nborLength", "nbors", "rmse", "rssi", "sequence"]
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 1000
  });

  function checkRole() {
    let spaceConfig = false
    if (window.location.href.includes("config/device")) {
      spaceConfig = true
    }
    var role = JSON.parse(localStorage.getItem("role"));
    let canEdit = false
    for (let i = 0; i < role.length; i++) {
      if (role[i].name === "Asset Attributes") {
        if (role[i].write === true) {
          canEdit = true
        }
      }
    }
    if (canEdit && spaceConfig) {
      setToggleEditBool(true)
    }
  }

  function getTenants() {
    TenantService.getTenants().then((value) => {
      setTenants(value.data.data)
    })
  }

  function getSpacesFloorplan(tenant) {
    setFloorplanUrl("blob:https://locus.redlore.com/c3cf693b-eaf2-43f8-a51c-115f15ff2e58")
    SpacesService.getSpaces(tenant).then((value) => {
      let res = value.data.data
      let filteredRes = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].floorplan !== null) {
          filteredRes.push(res[i])
        }
      }
      setValidSpace(filteredRes)
    })
  }

  useEffect(() => {
    checkRole()
    getTenants()
  }, []);

  async function onRowClick2(e) {
    let selectedData = selectedSAData
    let filteredData = selectedData.filter((sa) => {
      return sa.serving === e.data.serving
    });
    setSelectedSAData2(filteredData)
    await NetworkToolService.getAnchorLocation(e.data.serving).then(async (value) => {
      let anchorSpaceCoords = value.data.data.space_coordinates
      setAnchorLocation([anchorSpaceCoords.y, anchorSpaceCoords.x])
    })
    setMapTags(filteredData)
    tagMarkers()
    setMapVisible(true)
    setDataGrid3Visible(true)
  }

  function tagMarkers() {
    // const matchingTag = csvTagData.filter((tag) => tag.serial_number === selectedTag);
    const matchingTag = debugTagData
    let realTagPos = [0, 0, 0]
    if (matchingTag !== undefined) {
      if (matchingTag.length !== 0) {
        // realTagPos = [Number(matchingTag[0].y_axis), Number(matchingTag[0].x_axis), Number(matchingTag[0].height)]
        realTagPos = [matchingTag.y, matchingTag.x, 0]
      }
    }
    let tagCoords = []
    for (let i = 0; i < mapTags.length; i++) {
      let currTagCoord = mapTags[i].coords
      var partsArray = currTagCoord.split(',')
      partsArray = [Number(partsArray[0]), Number(partsArray[1]), Number(partsArray[2]), mapTags[i].tslocationtime, Number(mapTags[i].rmse)]
      tagCoords.push(partsArray)
    }
    var tagIcon = L.icon({
      iconUrl: diamondMarker,
      iconSize: [30, 30], // size of the icon
      iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var realTagIcon = L.icon({
      iconUrl: diamondMarkerRed,
      iconSize: [30, 30], // size of the icon
      iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var myIcon = L.icon({
      iconUrl: circle,
      iconSize: [10, 10], // size of the icon
      iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var firstIcon = L.icon({
      iconUrl: greenCircle,
      iconSize: [10, 10], // size of the icon
      iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var redIcon = L.icon({
      iconUrl: redCircle,
      iconSize: [10, 10], // size of the icon
      iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    return (
      <>
        {tagCoords.map((tag, i) =>
          <>
            <Marker id={tag[3]} key={i} position={[tag[1], tag[0]]} icon={tag[4] <= 1 ? myIcon : redIcon} draggable={false} ref={markerRef}>
              <Popup2 >
                RMSE: {tag[4]} <br></br>
                Time: {tag[3].substring(0, tag[3].length - 1)}
              </Popup2>
              <Tooltip >
                RMSE: {tag[4]} <br></br>
                Time: {tag[3].substring(0, tag[3].length - 1)}
              </Tooltip>
            </Marker>
          </>
        )}
        <Marker id={346289} key={423212} position={[anchorLocation[0], anchorLocation[1]]} icon={firstIcon} draggable={false} ref={markerRef}>
        </Marker>
        <Marker id={43212} key={4223212} position={realTagPos} icon={realTagIcon} draggable={false} ref={markerRef}>
        </Marker>
        <Circle center={realTagPos} radius={1} />
      </>
    )
  }

  async function onRowClick(e) {
    console.log("rowclock", e)
    getDebugTags(e.data.tag)
    setSelectedTag(e.data.tag)
    setSelectedSAData(e.data.data)
    let saData = e.data.data
    // const matchingTag = csvTagData.filter((tag) => tag.serial_number === e.data.tag);
    const matchingTag = debugTagData
    let realTagPos = [0, 0, 0]
    if (matchingTag !== undefined) {
      if (matchingTag.length !== 0) {
        // realTagPos = [Number(matchingTag[0].x_axis), Number(matchingTag[0].y_axis), Number(matchingTag[0].height)]
        realTagPos = [matchingTag.y, matchingTag.x, 0]
      }
    }
    console.log("realTagPos", realTagPos)
    console.log("e.data.data", e.data.data)
    let allData = e.data.data
    // allData.filter((n) => {
    //   return n.error < 35
    // });
    setSelectedAllData(allData)
    let distinctArray = distinctOutput(e.data.data)
    let distinctObjectArray = []
    let myPlotData = []
    for (let i = 0; i < distinctArray.length; i++) {
      let filteredData = saData.filter((sa) => {
        return sa.serving === distinctArray[i]
      });
      let rmseSum = 0
      let rmseGoodCount = 0
      let rmseBadCount = 0
      let losqiSum = 0
      let rssiSum = 0
      for (let j = 0; j < filteredData.length; j++) {
        losqiSum += Number(filteredData[j].losqi)
        rssiSum += Number(filteredData[j].rssi)
        rmseSum += Number(filteredData[j].rmse)
        if (Number(filteredData[j].rmse) <= 1) {
          rmseGoodCount++
        } else {
          rmseBadCount++
        }
      }
      let avgLosqi = losqiSum / filteredData.length
      let avgRssi = rssiSum / filteredData.length
      let avgRmse = rmseSum / filteredData.length
      let goodRmsePercent = rmseGoodCount / filteredData.length * 100
      let percentOfTotalRmse = rmseGoodCount / e.data.goodCount * 100


      let anchorLocation = [0, 0]
      await NetworkToolService.getAnchorLocation(distinctArray[i]).then(async (value) => {
        let anchorSpaceCoords = value.data.data.space_coordinates
        if (anchorSpaceCoords.x === null) {
          anchorLocation = [0, 0]
        } else {
          anchorLocation = [anchorSpaceCoords.x, anchorSpaceCoords.y]
        }

      })
      let distToAnchor = Math.sqrt((realTagPos[0] - anchorLocation[0]) ** 2 + (realTagPos[1] - anchorLocation[1]) ** 2)
      // setTotalScans(filteredData.length)
      distinctObjectArray.push({
        serving: distinctArray[i],
        avgRmse: Number(avgRmse.toFixed(3)),
        avgLosqi: avgLosqi.toFixed(0),
        avgRssi: avgRssi.toFixed(0),
        totalScans: filteredData.length,
        rmseGoodCount: rmseGoodCount,
        rmseBadCount: rmseBadCount,
        goodRmsePercent: goodRmsePercent.toFixed(1),
        distToAnchor: distToAnchor.toFixed(1),
        percentOfTotalRmse: percentOfTotalRmse.toFixed(1)
      })
      myPlotData.push({
        serving: distinctArray[i],
        avgRssi: avgRssi.toFixed(0),
        distToAnchor: distToAnchor.toFixed(1)
      })
    }
    setCsvExportData(distinctObjectArray)
    setSelectedTagData(distinctObjectArray)
    setPlotData(myPlotData)
    setMapVisible(false)
    setDataGrid2Visible(true)
  }


  let distinctOutput = (details) => {
    let unique_values = details
      .map((item) => item.serving)
      .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
      );
    console.log("unique_values", unique_values)
    return unique_values;
  };

  function onHiding2() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  function getFloorplan(spaceId) {
    console.log("spaceId", spaceId)
    SpacesService.getSpaceFloorplan(spaceId).then((res) => {
      let myFloorplan = URL.createObjectURL(res.data)
      setFloorplanUrl(myFloorplan)
    })
    SpacesService.getSpace(spaceId).then((res2) => {
      let response2 = res2.data
      let refSpaceBounds = response2.data.localCoordinates
      let correctedRefSpaceBounds = []
      for (let i = 0; i < refSpaceBounds.length; i++) {
        correctedRefSpaceBounds.push([refSpaceBounds[i][1], refSpaceBounds[i][0]])
      }
      let coords = correctedRefSpaceBounds
      let xMax = 0
      let yMax = 0
      for (let i = 0; i < coords.length; i++) {
        if (coords[i][0] > xMax) {
          xMax = coords[i][0]
        }
        if (coords[i][1] > yMax) {
          yMax = coords[i][1]
        }
      }
      setBounds(new latLngBounds([0, 0], [xMax, yMax]))
      setCenter([0 + xMax / 2, 0 + yMax / 2])
    })
  }

  async function onValueChanged2(e) {
    getFloorplan(e.value)
    setDataGrid2Visible(false)
    setMapVisible(false)
  }

  async function onValueChanged(e) {
    getSpacesFloorplan(e.value)
    setDataGrid2Visible(false)
    setMapVisible(false)
  }

  function MyGridlineLat() {
    if (show1m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lngDiff = lngMax - lngMin
      let lineArray = []
      let spacing = 1
      for (let i = 0; i < (lngDiff / spacing); i++) {
        lineArray.push([[latMin, i * spacing], [latMax, i * spacing]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={1}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function MyGridlineLng() {
    if (show1m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let latDiff = latMax - latMin
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lineArray = []
      let spacing = 1
      for (let i = 0; i < (latDiff / spacing); i++) {
        lineArray.push([[i * spacing, lngMin], [i * spacing, lngMax]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={1}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function MyGridlineLat5() {
    if (show5m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lngDiff = lngMax - lngMin
      let lineArray = []
      let spacing = 5
      for (let i = 0; i < (lngDiff / spacing); i++) {
        lineArray.push([[latMin, i * spacing], [latMax, i * spacing]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={2} visible={false}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function MyGridlineLng5() {
    if (show5m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let latDiff = latMax - latMin
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lineArray = []
      let spacing = 5
      for (let i = 0; i < (latDiff / spacing); i++) {
        lineArray.push([[i * spacing, lngMin], [i * spacing, lngMax]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={2}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function setGrid5() {
    setTimeout(function () {
      setShow5m(!show5m)
    }, 1);
  }

  function setGrid() {
    setTimeout(function () {
      setShow1m(!show1m)
    }, 1);
  }

  function uploadClick(e) {
    setUploadPopupVisible(true)
  }

  function uploadClick2(e) {
    setUploadPopupVisible2(true)
  }

  function uploadClick3(e) {
    setUploadPopupVisible3(true)
  }

  function onHidingUpload() {
    setUploadPopupVisible(false)
    setDataGrid1Visible(true)
  }

  function onHidingUpload2() {
    setUploadPopupVisible2(false)
  }

  function onHidingUpload3() {
    setUploadPopupVisible3(false)
    setDataGrid1Visible(true)
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
    let filename = event.target.files[0].name
    var partsArray = filename.split('-')
    setSelectedFileName(partsArray[1])
  }

  const handleFileSelect2 = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleFileSelect3 = (event) => {
    console.log("event.target", event.target)
    setSelectedFile2(event.target.files[0])
  }

  const handleSubmit = (event) => {
    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let currentCsvData = csvTagData2
        let tags = results.data
        tags = tags.filter((tag) => tag.coords !== "N/A")
        if (tags.length > 0) {
          let serialNumber = tags[0].serving
          NetworkToolService.getAnchorLocation(serialNumber).then((value) => {
            console.log("value", value)
            let spaceId = value.data.data.space_id
            getFloorplan(spaceId)
          })
        }
        console.log("tags", tags)
        let avgRmse = 0
        let goodCount = 0
        let badCount = 0
        for (let i = 0; i < tags.length; i++) {
          avgRmse += Number(tags[i].rmse)
          if (Number(tags[i].rmse) <= 1) {
            goodCount++
          } else {
            badCount++
          }
        }
        avgRmse = avgRmse / tags.length
        let goodRmsePercent = goodCount / tags.length * 100
        currentCsvData.push({
          tag: selectedFileName,
          data: tags,
          rmse: avgRmse.toFixed(3),
          totalScans: tags.length,
          goodCount: goodCount,
          badCount: badCount,
          goodRmsePercent: goodRmsePercent.toFixed(1)
        })
        setCsvTagData2(["test"])
        setCsvTagData2(currentCsvData)
      },
    });
    onHidingUpload()
  }

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(csvTagData2)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";

    link.click();
  };

  const handleSubmit3 = (event) => {
    event.preventDefault();
    console.log(event.target)
    console.log("selectedFile2", selectedFile2)
    let file = selectedFile2
    let jsonData
    const reader = new FileReader();



    reader.onload = function (e) {

      const text = e.target.result;

      // console.log(text);
      // jsonData = text
      let pasedJson = JSON.parse(text)
      console.log("pasedJson", pasedJson)
      setCsvTagData2(pasedJson)

    };

    reader.readAsText(file);

    onHidingUpload3()
  }

  async function getDebugTags(serialNumber) {
    // console.log("serialNumber",serialNumber)
    NetworkToolService.getTagLocationDebug(serialNumber).then((value) => {
      setDebugTagData(value.data.data[0].actual_coordinates)
      // console.log("value!", value)
    })
  }

  const handleSubmit2 = (event) => {
    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvTagData(results.data)
      },
    });
    onHidingUpload2()
  }

  const dataGridRef = useRef()
  window.onload = function () {
    // your code 

    document.getElementById('import').onclick = function () {
      var files = document.getElementById('selectFiles').files;
      console.log(files);
      if (files.length <= 0) {
        return false;
      }

      var fr = new FileReader();

      fr.onload = function (e) {
        console.log(e);
        var result = JSON.parse(e.target.result);
        var formatted = JSON.stringify(result, null, 2);
        document.getElementById('result').value = formatted;
      }

      fr.readAsText(files.item(0));
    };
  };

  // const countriesInfo = service.getCountriesInfo();
  // const energySources = service.getEnergySources();
  // const types = ['line', 'stackedline', 'fullstackedline'];
  // const seriesTypeLabel = { 'aria-label': 'Series Type' };

  async function onValueChangedX(e) {
    setAxisX(e.value)
  }
  async function onValueChangedY(e) {
    setAxisY(e.value)
  }

  async function onValueChangedX2(e) {
    setAxisX2(e.value)
  }
  async function onValueChangedY2(e) {
    setAxisY2(e.value)
  }

  const customizeTooltip = (arg) => {
    return {
      text: `Serving Anchor: ${arg.point.data.serving}`
    };
  };

  function onToggle() {
    setToggleSwitch(!toggleSwitch)
  }

  function onToggle2() {
    setToggleSwitch2(!toggleSwitch2)
  }

  return (
    <div className='networkTool-container'>
      <div className='networkTool-spaceDropdown'>
        {/* <div className='dateBtn'>
          <SelectBox
            placeholder="Select a Tenant"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={tenants}
            stylingMode="outlined"
            onValueChanged={onValueChanged}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
        </div> */}
        {/* <div className='dateBtn'>
          <SelectBox
            placeholder="Select a Space"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={validSpace}
            stylingMode="outlined"
            onValueChanged={onValueChanged2}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
        </div> */}

        <div className="uploadBtntags" title={"Upload data"} onClick={uploadClick} key={'uploadbtntagsbtns'}>
          Upload data
          <i className="dx-icon-upload" style={{ "fontSize": "24px" }}></i>
        </div>
        {/* <div className="uploadBtntags" title={"Upload tags"} onClick={uploadClick2} key={'uploadbtntagsbtns2'}>
          &nbsp; &nbsp; Upload tags
          <i className="dx-icon-upload" style={{ "fontSize": "24px" }}></i>
        </div> */}
        <div className="uploadBtntags" title={"Upload bulk"} onClick={uploadClick3} key={'uploadbtntagsbtns3'}>
          &nbsp; &nbsp; Upload bulk
          <i className="dx-icon-upload" style={{ "fontSize": "24px" }}></i>
        </div>
      </div>
      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={csvTagData2}
        showBorders={false}
        onRowClick={onRowClick}
        visible={dataGrid1Visible}
        keyExpr="tag"
        ref={dataGridRef}
        noDataText="No Data found"
      >
        <Editing
          mode="row"
          allowDeleting={true}
        />
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        <Column
          dataField={'tag'}
          caption={'Serial Number'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'rmse'}
          dataType={'number'}
          caption={'Avg Rmse'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'totalScans'}
          dataType={'number'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'goodCount'}
          dataType={'number'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'badCount'}
          dataType={'number'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'goodRmsePercent'}
          dataType={'number'}
          allowEditing={false}
          allowFiltering={false}
        />
        {/* <Column type="buttons" visible={toggleEditBool} width={150}>
          <Button name="edit" />
        </Column> */}
      </DataGrid>
      {/* <div className={dataGrid1Visible ? 'networkTool-export2' : 'dateBtn3-none'}>
        <CSVLink style={{ textDecoration: 'none', color: 'black' }} filename={`Analyze-redboard.csv`} data={bulkExportData}>Export</CSVLink>
      </div> */}
      <button className={dataGrid1Visible ? 'networkTool-export3' : 'dateBtn3-none'} type="button" onClick={exportData}>
        Export
      </button>
      <div className={toggleSwitch ? 'networkTool-spaceDropdown' : 'dateBtn3-none'}>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select X-axis"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={axisLabels2}
            stylingMode="outlined"
            onValueChanged={onValueChangedX2}
          >
          </SelectBox>
        </div>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select Y-axis"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={axisLabels2}
            stylingMode="outlined"
            onValueChanged={onValueChangedY2}

          >
          </SelectBox>
        </div>
      </div>
      <div className={toggleSwitch ? 'networkTool-chart' : 'dateBtn3-none'}>
        <Chart
          dataSource={selectedAllData}
          visible={toggleSwitch}
          id="chart"
        >
          <CommonSeriesSettings type={'scatter'} />
          <ValueAxis valueType="numeric" title={axisY2}></ValueAxis>{/* y axis */}
          <ArgumentAxis argumentType="numeric" title={axisX2}></ArgumentAxis>{/* x axis */}
          <Legend visible={false} />
          <Series
            visible={toggleSwitch}
            valueField={axisY2}
            argumentField={axisX2}
            valueType={"numeric"}
            argumentType={"numeric"}
          />
          <Tooltip2 enabled={true} customizeTooltip={customizeTooltip} />
        </Chart>
      </div>
      <div className={toggleSwitch2 ? 'networkTool-spaceDropdown' : 'display-none'}>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select X-axis"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={axisLabels}
            stylingMode="outlined"
            onValueChanged={onValueChangedX}
          >
          </SelectBox>
        </div>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select Y-axis"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={axisLabels}
            stylingMode="outlined"
            onValueChanged={onValueChangedY}

          >
          </SelectBox>
        </div>
      </div>
      <div className={toggleSwitch2 ? 'networkTool-chart' : 'display-none'}>
        <Chart
          id="chart2"
          dataSource={selectedTagData}
          visible={toggleSwitch2}
        >
          <CommonSeriesSettings type={'scatter'} />
          <ValueAxis valueType="numeric" title={axisY}></ValueAxis>{/* y axis */}
          <ArgumentAxis argumentType="numeric" title={axisX}></ArgumentAxis>{/* x axis */}
          <Legend visible={false} />
          <Series
            visible={toggleSwitch2}
            valueField={axisY}
            argumentField={axisX}
            valueType={"numeric"}
            argumentType={"numeric"}
          />
          <Tooltip2 enabled={true} customizeTooltip={customizeTooltip} />
        </Chart>
      </div>
      <div className={dataGrid2Visible ? 'networkToolAnalyze-toggles' : 'display-none'}>
        <div className='networkToolAnalyze-togglesText'>
          Graph All Tag Data
        </div>
        <Switch
          value={toggleSwitch}
          onValueChanged={onToggle}
        />
        <div className='networkToolAnalyze-togglesText'>
          Graph Serving Anchor Data
        </div>
        <Switch
          value={toggleSwitch2}
          onValueChanged={onToggle2}
        />
      </div>
      {console.log("toggleSwitch2", toggleSwitch2)}
      {console.log("toggleSwitch", toggleSwitch)}
      {/* <input type="file" id="selectFiles" value="Import" /><br /> */}
      {/* <button id="import">Import</button> */}
      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={selectedTagData}
        showBorders={false}
        onRowClick={onRowClick2}
        visible={dataGrid2Visible}
        keyExpr="serving"
        ref={dataGridRef}
        noDataText="No Data Found"
      >
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        <Column
          dataField={'serving'}
          caption={'Serving Anchor'}
          allowEditing={false}
          alignment='left'
        />
        <Column
          dataField={'avgRmse'}
          dataType={'number'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'avgLosqi'}
          dataType={'number'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'avgRssi'}
          dataType={'number'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'distToAnchor'}
          dataType={'number'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'totalScans'}
          dataType={'number'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'rmseGoodCount'}
          dataType={'number'}
          allowEditing={false}
        />
        <Column
          dataField={'rmseBadCount'}
          dataType={'number'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'goodRmsePercent'}
          dataType={'number'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'percentOfTotalRmse'}
          caption={'Good % of Total Good'}
          dataType={'number'}
          allowEditing={false}
          allowFiltering={false}
        />
      </DataGrid>
      <div className={dataGrid2Visible ? 'networkTool-export2' : 'dateBtn3-none'}>
        <CSVLink style={{ textDecoration: 'none', color: 'black' }} filename={`Analyze-Tag-${selectedTag}-redboard.csv`} data={csvExportData}>Export</CSVLink>
      </div>

      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={selectedSAData2}
        showBorders={false}
        visible={dataGrid3Visible}
        keyExpr="tsuwbTdoaTime"
        ref={dataGridRef}
        noDataText="No Data Found"
      >
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        <Column
          dataField={'serving'}
          caption={'Serving Anchor'}
          allowEditing={false}
          alignment='left'
        />
        {/* <Column
          dataField={'tslocationtime'}
          dataType="datetime"
          format="EEE MMM dd yyyy, HH:mm:ss:SSS"
          caption={'Time'}
          allowFiltering={false}
          allowEditing={false}
          width={250}
        /> */}
        <Column
          dataField={'tsuwbTdoaTime'}
          dataType="datetime"
          format="yyyy-MM-ddTHH:mm:ss:SSS"
          caption={'ts_uwb_tdoa Time'}
          allowFiltering={false}
          allowEditing={false}
          width={250}
        />
        <Column
          dataField={'sequence'}
          dataType={'number'}
          allowEditing={false}
        />
        <Column
          dataField={'coords'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'error'}
          dataType={'number'}
          caption={'Error (m)'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'rmse'}
          caption={'RMSE'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'losqi'}
          dataType={'number'}
          caption={'LOSQI'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'rssi'}
          dataType={'number'}
          caption={'RSSI'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'nborLength'}
          dataType={'number'}
          caption={'Nbor Anchors (Total)'}
          allowEditing={false}
          alignment="left"
          width={"180px"}
        />
      </DataGrid>
      <div style={{ height: '100%', width: '100%' }} ref={listRef} className={mapVisible ? 'networkTool-visible' : 'dateBtn3-none'}>
        <MapContainer
          id="map"
          center={center}
          style={{ height: '800px', width: '100%' }}
          zoom={4}
          min-zoom={2}
          maxZoom={12}
          zoomSnap={0}
          crs={CRS.Simple}
        >
          <ImageOverlay
            url={floorplanUrl}
            bounds={bounds}
            style={{ height: '100px', width: '100px' }}
            opacity={1}
            zIndex={10}
          />
          {tagMarkers()}
          <MyGridlineLng></MyGridlineLng>
          <MyGridlineLat></MyGridlineLat>
          <MyGridlineLng5></MyGridlineLng5>
          <MyGridlineLat5></MyGridlineLat5>
          <div className='legend'>
            <div className='legendText'>
              <img src={diamondMarkerRed} className='networkTool-logo' alt='' /><br></br>
              <img src={circle} className='networkTool-logo' alt='' /><br></br>
              <img src={redCircle} className='networkTool-logo' alt='' /><br></br>
              <img src={greenCircle} className='networkTool-logo' alt='' />
            </div>
            <div className='legendTextBeside'>
              <div className='legendTextBesideEach'>
                Real Tag Position
              </div>
              <div className='legendTextBesideEach'>
                Tag Position Good
              </div>
              <div className='legendTextBesideEach'>
                Tag Position Bad
              </div>
              <div className='legendTextBesideEach'>
                Serving Anchor
              </div>
            </div>
          </div>
          <div className='floorplan-5mGrid' onClick={setGrid5}>
            5m Grid
          </div>
          <div className='floorplan-1mGrid' onClick={setGrid}>
            1m Grid
          </div>
        </MapContainer>
      </div>
      <div ref={messagesEndRef} />
      <Popup
        visible={uploadPopupVisible}
        onHiding={onHidingUpload}
        dragEnabled={false}
        height={150}
        width={600}
        showCloseButton={true}
        showTitle={true}
        title="Upload CSV"
        container=".dx-viewport"
        key={'uploadCsv2'}
      >
        <>
          <Toast
            setParentToastConfig={setToastConfig}
            toastBody={toastConfig}
          />
          <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileSelect} />
            <input type="submit" value="Upload File" />
          </form>
        </>
      </Popup>
      <Popup
        visible={uploadPopupVisible2}
        onHiding={onHidingUpload2}
        dragEnabled={false}
        height={150}
        width={600}
        showCloseButton={true}
        showTitle={true}
        title="Upload CSV"
        container=".dx-viewport"
        key={'uploadCsv3'}
      >
        <>
          <Toast
            setParentToastConfig={setToastConfig}
            toastBody={toastConfig}
          />
          <form onSubmit={handleSubmit2}>
            <input type="file" onChange={handleFileSelect2} />
            <input type="submit" value="Upload File" />
          </form>
        </>
      </Popup>
      <Popup
        visible={uploadPopupVisible3}
        onHiding={onHidingUpload3}
        dragEnabled={false}
        height={150}
        width={600}
        showCloseButton={true}
        showTitle={true}
        title="Upload CSV"
        container=".dx-viewport"
        key={'uploadCsv4'}
      >
        <>
          <Toast
            setParentToastConfig={setToastConfig}
            toastBody={toastConfig}
          />
          <form onSubmit={handleSubmit3}>
            <input type="file" onChange={handleFileSelect3} />
            <input type="submit" value="Upload File" />
          </form>
        </>
      </Popup>
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding2}
        displayTime={toastConfig.displayTime}
      />
    </div>
  )
}