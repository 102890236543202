import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Popup,
  Column,
  Paging,
  FilterRow,
  Editing,
  StateStoring,
  HeaderFilter,
  SearchPanel,
  Form,
  Button,
  Texts,
  ColumnFixing,
  LoadPanel,
  Export,
  FilterPanel,
  Scrolling
} from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import {Button as SelectButton} from 'devextreme-react/button';
import * as DOMPurify from 'dompurify';
import ScrollView from 'devextreme-react/scroll-view';
import { Popup as CommonEditDevicePopup, Popup as DeviceStatusUpdatePopup, Popup as EditDeviceSettingsPopup, Popup as AssignDevicePopup, Popup as ColumnChooserDevicePopup, Position } from 'devextreme-react/popup';
import { CheckBox } from 'devextreme-react/check-box';
import generatePDF, { Margin } from 'react-to-pdf';
import TenantService from 'api/tenant.service';
import DevicesService from 'api/devices.service';
import SpacesService from 'api/spaces.service';
import Toast from 'components/Toast';
import DeviceConfigItems from 'components/DeviceConfigItems';
import SettingsIcon from 'assets/icons/settings.svg';
import  './devices.css'
import DataSource from 'devextreme/data/data_source';
// import socketIO from 'socket.io-client';
import moment from 'moment-timezone';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
const dataSource = new DataSource({
  store: [],
  key: 'id',
  searchMode: 'delay', // Use 'delay' mode for better user experience
  searchTimeout: 800, // Delay in milliseconds
})



// function exportGridDevices(e) {
//   const workbook = new Workbook(); 
//   const worksheet = workbook.addWorksheet("Devices"); 
//   exportDataGrid({ 
//       worksheet: worksheet, 
//       component: e.component
//   }).then(function() {
//       workbook.xlsx.writeBuffer().then(function(buffer) { 
//           saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Devices.xlsx"); 
//       }); 
//   });
//   e.cancel = true; 
// }

export default function Devices() {
  const scrollViewRefEditDevice = useRef(null);
  const dataGridRef = useRef()
  const downloadTargetRef = useRef();
  const [data, setData] = useState([])
  const [spaceData, setSpaceData] = useState([])
  const myTenant = TenantService.getTenant();
  const [toggleEditBool, setToggleEditBool] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showSettingPopup, setShowSettingPopup] = useState(false)
  const [showCommonSettingPopup, setShowCommonSettingPopup] = useState(false)
  const [showDeviceStatusUpdatePopup, setShowDeviceStatusUpdatePopup] = useState(false)
  const [tenants, setTenants] = useState([])
  const [selectedTenant, setSelectedTenant] = useState()
  const [deviceName, setDeviceName] = useState()
  const [deviceId, setDeviceId] = useState()
  const [showMapTenantBtn, setShowMapTenantBtn] = useState(false)
  const [visibleDeviceColumnChooser, setVisibleDeviceColumnChooser] = useState(true)
  const [popupVisibleDeviceColumnChooser, setPopupVisibleDeviceColumnChooser] = useState(false)
  const [forceUpdateDevice, setForceUpdateDevice] = useState(false)
  const [configData, setConfigData] = useState([])
  const [heightOfGrid, setHeightOfGrid] = useState('calc(100vh - 102px)');
  const [loading, setLoading] = useState(false);
  const [isSpaceConfig, setIsSpaceConfig] = useState(false);
  const [deviceStatuses, setDeviceStatuses] = useState([{id:1, name:""}])
  const [selectedStatus, setSelectedStatus] = useState()
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 1000,
  });
  // const [apiInProgress, setApiInProgress] = useState(false);
  // const WAIT_TIME = 10000;
  // let recursiveDeviceRequest = null
  const [socket, setSocket] = useState(null)
  const [loadingText, setLoadingText] = useState("Loading devices...")
  // const [filterValue, setFilterValue] = useState([])
  // const filterBuilderPopupPosition = {
  //   of: window,
  //   at: 'top',
  //   my: 'top',
  //   offset: { y: 10 },
  // };
  function exportGridDevices(e){
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Devices');
    worksheet.columns = [
      { width: 5 },
      { width: 30 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 40 },
    ];
    exportDataGrid({
      component: e.component,
      worksheet,
      keepColumnWidths: false,
      topLeftCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'lastUpdate') {
            excelCell.value = new Date(excelCell.value).toLocaleString('en-US', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            });
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Devices-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`);
      });
    });
  };

  // const downloadReport = async() => {
  //   setLoading(true);
  //   if (myTenant === 'ee994c57-31db-4816-b974-059ac704acd2') await setHeightOfGrid('20000px');
  //   else await setHeightOfGrid('100%');
  //   await generatePDF(downloadTargetRef, {
  //     filename: `Devices-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`,
  //     page: { margin: Margin.LARGE }
  //   });
  //   await setHeightOfGrid('calc(100vh - 51px)');
  //   setLoading(false);
  // }

  const downloadReport = () => {
    const grid = dataGridRef.current.instance;
    const columns = grid.getVisibleColumns().filter(col => col.dataField).map(col => ({ header: col.caption, dataField: col.dataField }));
    const rows = grid.getVisibleRows().map(row => {
      return columns.map(col => {
        const nestedKeys = col.dataField.split('.');
        let value = row.data;
        nestedKeys.forEach(key => {
          if (value && typeof value === 'object') {
            value = value[key];
          } else {
            value = undefined;
          }
        });
        if (col.dataField === 'lastUpdate' && value !== '' && value !== undefined && value !== null) {
          // Apply custom formatting for 'someField'
          value = new Date(value).toLocaleString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          });
        }
        return value;
      });
    });
    
    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows,
      headStyles: { 
        fillColor: [181, 22, 22], // Red color
        textColor: [255, 255, 255] // White text color
      }
    });
    doc.save(`Devices-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
};

  function checkRole() {
    let spaceConfig = false
    if (window.location.href.includes("config/device")) {
      spaceConfig = true
      setIsSpaceConfig(true)
      // clearInterval(recursiveDeviceRequest)
      const menuItems = JSON.parse(localStorage.getItem('menu'))
      menuItems.map((item) => {
        if(item.text === 'Configuration'){
          item.items.map((data) => {
            if(data.path === '/config/tenants'){
              setShowMapTenantBtn(true)
            }
          })
        }
      })
    }
    
    const roles = JSON.parse(localStorage.getItem("role"));
    const canEdit = roles.filter((role, index)=>{
      return role.name === 'Asset Attributes'
    })[0];

    if (canEdit?.write && spaceConfig) {
      setToggleEditBool(true);
    }

  }

  function allowUpdating(e) {
    let role = e?.row?.data?.role
    if (role?.id === 2 || role?.id === 3) return true;
    return false;
  }

  function getSpaces() {
    let tenant = TenantService.getTenant();
    SpacesService.getSpaces(tenant).then((value) => {
      let mydata = value.data.data
      mydata.unshift({
        "name": "None",
        "id": "null"
    })
      setSpaceData(mydata)
    })
  }

  // useEffect(()=> {
  //   const socketClient = socketIO.connect(`${process.env.REACT_APP_API_URL}`, {forceNew: true, path:"/api/socket/"});
  //   setSocket(socketClient)
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'visible') {
  //       if (!socketClient.connected) {
  //         socketClient.connect();
  //       }
  //     }
  //   };  

  //   // Add an event listener for visibility change
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
    
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     socketClient.disconnect();
  //   };
  // },[])

  // useEffect(() => {
  //   if(socket === null) return false
  //   // Update record
  //   socket.on(`editDeviceResponse-${myTenant}`, (data) => {
  //     var recordIndex = dataSource.store()._array.findIndex(function(item) {
  //       return item.id == data.device.id;
  //     });
    
  //     if (recordIndex !== -1) {
  //       dataSource.store()._array[recordIndex].space = data.device.space
  //       dataSource.store()._array[recordIndex].statusUpdateRate = data.device.statusUpdateRate;
  //       dataSource.reload();
  //     }
      
  //   });
  //   // this is from Redis subscribe mechanism
  //   socket.on(`editDeviceResponseRedis-${myTenant}`, (data) => {
  //     console.log("Data recie 122", data);
  //     var recordIndex = dataSource.store()._array.findIndex(function(item) {
  //       return item.id == data.id;
  //     });
    
  //     if (recordIndex !== -1) {
  //       dataSource.store().update({id: data.id}, {
  //         online: data.online !== undefined ? data.online : dataSource.store()._array[recordIndex].online,
  //         lastUpdate: data.lastUpdate !== undefined ? data.lastUpdate : dataSource.store()._array[recordIndex].lastUpdate,
  //         battery: data.battery !== undefined ? data.battery : dataSource.store()._array[recordIndex].battery
  //       });
  //       // dataSource.store()._array[recordIndex].online = data.online !== undefined ? data.online : dataSource.store()._array[recordIndex].online;
  //       // dataSource.store()._array[recordIndex].lastUpdate = data.lastUpdate !== undefined ? data.lastUpdate : dataSource.store()._array[recordIndex].lastUpdate;
  //       // dataSource.store()._array[recordIndex].battery = data.battery !== undefined ? data.battery : dataSource.store()._array[recordIndex].battery;
  //       dataSource.load();
  //     }
  //   });

  //   // Add record
  //   socket.on(`addDeviceResponse-${myTenant}`, (data) => {
  //     dataSource.store()._array.push(data)
  //     dataSource.load();
  //   });

  //   // Remove record
  //   socket.on(`deleteAssetResponse-${myTenant}`, (data) => {
  //     var deleteIndex = dataSource.store()._array.findIndex(function(item) {
  //       return item.id == data;
  //     });
  //     dataSource.store()._array.splice(deleteIndex, 1)
  //     dataSource.load();
  //   });
  // }, [socket])

  async function getData(reload = false) {
    let tenant = TenantService.getTenant();
    DevicesService.getDevices(tenant).then((value) => {
      let response=value.data
      appendDevicesData(response.data)
      setLoadingText("No Data")
    })
  }

  async function getConfigData(deviceId, tenantId){
    DevicesService.getDeviceConfigData(deviceId, tenantId).then((value) => {
      setConfigData(value.data?.data || []);
    })
  }

  function appendDevicesData(newRecords) {
    dataSource.store()._array = newRecords;
    dataSource.reload();
 }

  useEffect((async) => {
    checkRole()
    allStorage()
    getData(false)
    getSpaces()
    getTenants()
    getDeviceStatusList()
  }, []);

  function getBattery(rowData) {
    if (rowData.status === 'offline'){
      return rowData.battery + "% ?";
    }
    return rowData.battery + "%";
  }

  function buttonColor(e) {
    if (e.column.dataField === 'battery' && e.rowType !== "header") {
      if (e.data !== undefined) {
        if (e.data.battery !== undefined) {
          if (e.data.battery > 50) {
            e.cellElement.style.color = "#12DF1C";
          }
          if (e.data.battery <= 50 && e.data.battery > 30) {
            e.cellElement.style.color = "#F29D00";
          }
          if (e.data.battery <= 30) {
            e.cellElement.style.color = "#FF0000";
          }
        }
      }
    }

    if (e.column.dataField === 'status' && e.rowType !== "header") {
      if (e.data !== undefined) {
        if (e.data.status !== undefined) {
          if (e.data.status === 'online') {
            e.cellElement.style.color = "#12DF1C";
          } else if (e.data.status === 'offline') {
            e.cellElement.style.color = "#FF0000";
          } else{
            e.cellElement.style.color = "#FF0000";
          }
        }
      }
    }
    // if (e.column.dataField === 'offlineSleepSupported' && e.rowType !== "header") {
    //   if (e.data !== undefined) {
    //     if (e.data.offlineSleepSupported !== undefined) {
    //       if (e.data.offlineSleepSupported === 'null') {
    //         e.cellElement.style.color = "#12DF1C";
    //       } else if (e.data.online === 'offline') {
    //         e.cellElement.style.color = "#FF0000";
    //       } else{
    //         e.cellElement.style.color = "#FF0000";
    //       }
    //     }
    //   }
    // }
  }

  function selectedTenantByUser(e){
    setSelectedTenant(e.value)
  }

  function updatedDeviceStatus(e){
    setSelectedStatus(e.value)
  }

  function allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      values.push({
        key: keys[i],
        val: localStorage.getItem(keys[i])
      });
    }
  }

  function onRowUpdating(e) {
    let spaceId
    if (e.newData.space !== undefined ){
      spaceId = e.newData.space.id
    }
    
    if (spaceId === undefined) {
      spaceId = e.oldData.space.id
    }
    if (e.newData.space && e.newData.space.id === "null") {
      spaceId = null
    }
    let id = e.key.id
    let proximityDistance = e.newData.space && e.newData.space.proximityDistance ? e.newData.space.proximityDistance : ""
    let statusUpdateRate = e.newData.statusUpdateRate
    if (e.oldData.role.id === 3) {
      if (proximityDistance === undefined) {
        proximityDistance = e.oldData.space.proximityDistance
      }

      const updatedInfo = {
        id: e.key.id,
        space: {
          id: spaceId,
          name: spaceData.reduce((result, item) => (item.id === spaceId ? item.name : result), null),
          proximityDistance: proximityDistance
        },
        statusUpdateRate: statusUpdateRate
      }
      if (proximityDistance === undefined || proximityDistance === null) {
        getData(false)
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'error',
          status: 500,
          message: "Proximity Distance is Required for Proximity Anchor",
        });
      } else {
        DevicesService.editDevice(id, spaceId, statusUpdateRate, proximityDistance ).then((res) => {
          // getData(false)
          if(res.status === 200 || res.data.status === 200){
            // socket.emit(`editDevice`, {device: updatedInfo, socketID: socket.id, tenant: myTenant})
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              type: 'success',
              status: res?.data?.status ? res.data.status : res?.status,
              message: res?.data?.message ? res.data.message : res?.message,
            });
            window.location.reload()
          }
        })
      }
    } else {
      const updatedInfo = {
        id: e.oldData.id,
        space: {
          id: spaceId,
          name: spaceData.reduce((result, item) => (item.id === spaceId ? item.name : result), null),
        },
        statusUpdateRate: statusUpdateRate
      }
      DevicesService.editDevice(id, spaceId, statusUpdateRate).then((res) => {
        if(res.status === 200 || res.data.status === 200){
          // socket.emit(`editDevice`, {device: updatedInfo, socketID: socket.id, tenant: myTenant})
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'success',
            status: res?.data?.status ? res.data.status : res?.status,
            message: res?.data?.message ? res.data.message : res?.message,
          });
          window.location.reload()
        }
      })
    }
  }

  // TODO: check if this method is required or needs to be removed.
  function valueChanged(e) {
  }
  function onStatusUpdate(e) {
    // if(e !== ''){
    //   clearInterval(recursiveDeviceRequest);
    //   setApiInProgress(true);
    // } else {
    //   setApiInProgress(false);
    // }
    // setLoadingText("No Data")
  }

  function openPopup(e){
    // console.log("VAL openPopup: ", e);
    setShowCommonSettingPopup(false)
    // setDeviceId(e.row.data.id)
    // setDeviceName(e.row.data.serialNumber)
    setTimeout(() => {
      setShowPopup(true);  
    }, 1000);
    
  }

  async function openDeviceStatusPopup(){
    setShowCommonSettingPopup(false)
    setTimeout(() => {
      setShowDeviceStatusUpdatePopup(true)
    }, 1000);
  }

  function openSettingPopup(e){
    setShowCommonSettingPopup(false)
    console.log("VAL: ", e);
    
    getConfigData(deviceId, myTenant)
    // setDeviceId(e.row.data.id)
    // setDeviceName(e.row.data.serialNumber)
    setTimeout(() => {
      setShowSettingPopup(true);
    }, 1000);
    
  }

  function openCommonDevicePopup(e){
    setShowCommonSettingPopup(true)
    console.log('data: ', e.row.data);
    setDeviceId(e.row.data.id)
    setDeviceName(e.row.data.serialNumber)
  }

  function onHiding() {
    setShowPopup(false)
    setSelectedTenant(null)
    setDeviceId()
    setDeviceName()
  }

  function onHidingCommonEditDevicePopup(){
    setShowCommonSettingPopup(false)
  }

  function onHidingDeviceStatusUpdatePopup(){
    setShowDeviceStatusUpdatePopup(false)
  }

  function onHidingSettingPopup(){
    setConfigData([])
    setShowSettingPopup(false)
    setSelectedTenant(null)
    setDeviceId()
    setDeviceName()
  }

  function getTenants() {
    TenantService.getTenants().then((value) => {
      let response=value?.data
      setTenants(response?.data)
    })
  }

  function getDeviceStatusList() {
    DevicesService.getDeviceStatusList().then((value) => {
      let response=value?.data
      console.log(response?.data);
      setDeviceStatuses(response?.data)
    })
  }

  function updateTenant(){
    DevicesService.updateDeviceTenant(selectedTenant, deviceId).then(async (response) => {
      setDeviceId(null)
      setSelectedTenant(null)
      getData(false)
      setShowPopup(false)
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: response.status===200 ? 'success' : 'error',
        status: response?.data?.status ? response.data.status : response?.status,
        message: response?.data?.message ? response.data.message : response?.message,
      });
    })
  }

  function updateDeviceStatus(){
    if (!deviceId || !selectedStatus) return true;
    DevicesService.updateDeviceStatus(deviceId, selectedStatus).then(async (response) => {
      setDeviceId(null)
      getData(false)
      setSelectedStatus()
      setShowDeviceStatusUpdatePopup(false)
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: response.status===200 ? 'success' : 'error',
        status: response?.data?.status ? response.data.status : response?.status,
        message: response?.data?.message ? response.data.message : response?.message,
      });
    })
  }

  function updateDeviceConfig(){
    console.log('configData 123: ', configData);
    const outputArray = configData.map(item => {
      if (item.newValue !== undefined){
        return {
          key: item.device_setting_id,
          value: String(item.newValue)
        };
      }
    }).filter(item => item !== undefined);
    console.log('outputArr: ', outputArray);
    DevicesService.updateDeviceConfig(deviceId, myTenant, outputArray ).then(async (response) => {
      setDeviceId(null)
      setSelectedTenant(null)
      setShowSettingPopup(false)
      getData(false)
      setShowPopup(false)
      setConfigData([])
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: response.status===200 ? 'success' : 'error',
        status: response?.data?.status ? response.data.status : response?.status,
        message: response?.data?.message ? response.data.message : response?.message,
      });
    })
  }

  function columnChooser(e) {
    setVisibleDeviceColumnChooser(!visibleDeviceColumnChooser)
    setPopupVisibleDeviceColumnChooser(!popupVisibleDeviceColumnChooser)
    localStorage.setItem("visibleDeviceColumnChooser", !visibleDeviceColumnChooser)
  }

  function onInitialized(e) {
    if (localStorage.getItem("visibleDeviceColumnChooser") === "false") {
      setVisibleDeviceColumnChooser(false)
    } else if (localStorage.getItem("visibleDeviceColumnChooser") === "true") {
      setVisibleDeviceColumnChooser(true)
    } else {
      setVisibleDeviceColumnChooser(true)
    }
  }

  function hideInfo() {
    // setPopupVisibleDevice(false)
    setPopupVisibleDeviceColumnChooser(false)
    
  }

  function showColumn(colName) {
    if (localStorage.getItem(myTenant + "visibleColsDevice") !== undefined && localStorage.getItem(myTenant + "visibleColsDevice") !== null) {
      var storedArray = JSON.parse(localStorage.getItem(myTenant + "visibleColsDevice"));
      let found = false
      for (let i = 0; i < storedArray.length; i++) {
        if (storedArray[i].name === colName) {
          found = true
          return storedArray[i].value
        }
      }
      if (!found) {
        storedArray.push({
          name: colName,
          value: false
        })
        localStorage.setItem(myTenant + "visibleColsDevice", JSON.stringify(storedArray));
        return false
      }
    } else {
      var storedArray2 = []
      storedArray2.push({
        name: colName,
        value: false
      })
      localStorage.setItem(myTenant + "visibleColsDevice", JSON.stringify(storedArray2));
      return false
    }
  }

  function selectColumn(name) {
    var storedArray = JSON.parse(localStorage.getItem(myTenant + "visibleColsDevice"));
    if (storedArray === null) {
      storedArray = []
      storedArray.push({
        name: name,
        value: true
      })
      localStorage.setItem(myTenant + "visibleColsDevice", JSON.stringify(storedArray));
    } else {
      let found = false
      for (let i = 0; i < storedArray.length; i++) {
        if (storedArray[i].name === name) {
          storedArray[i].value = !storedArray[i].value
          found = true
        }
      }
      if (!found) {
        storedArray.push({
          name: name,
          value: true
        })
      }
      localStorage.setItem(myTenant + "visibleColsDevice", JSON.stringify(storedArray));
    }
    setForceUpdateDevice(!forceUpdateDevice)
  }

  

  function chooserColumn() {
    return (
      <>
        <ScrollView width='100%' height='100%'>
          <div className='device-columnChooserContainer'>
            <div className='device-columnCheckbox' onClick={() => { selectColumn("uplinkRate") }}>
              <CheckBox value={showColumn("uplinkRate")} disabled={true} />
              &nbsp; Uplink Rate
            </div>
            <div className='device-columnCheckbox' onClick={() => { selectColumn("stationaryUplinkRate") }}>
              <CheckBox value={showColumn("stationaryUplinkRate")} disabled={true} />
              &nbsp; Stationary Uplink Rate
            </div>
            <div className='device-columnCheckbox' onClick={() => { selectColumn("motionUplinkRate") }}>
              <CheckBox value={showColumn("motionUplinkRate")} disabled={true} />
              &nbsp; Motion Uplink Rate
            </div>
            <div className='device-columnCheckbox' onClick={() => { selectColumn("offlineSleepSupported") }}>
              <CheckBox value={showColumn("offlineSleepSupported")} disabled={true} />
              &nbsp; Offline Sleep Supported
            </div>
          </div>
        </ScrollView>
      </>
    )
  }

//   const onOptionChanged = (e) => {
//     if(e.fullName === "filterValue") {
//       applyFilter(e.value);
//   }    
//   }
//   const applyFilter = (filterExpression) => {
//     setFilterValue(filterExpression)
// }

  const spaceEditorOptions = {
    dataSource: spaceData,
    searchEnabled: true,
    value: '',
    displayExpr: "name",
    valueExpr: "id",
    acceptCustomValue: false
  }

  const handleDeviceSettings = async(data, deviceSettings) => {
    let list  = configData;
    const index = await configData.findIndex(record => record.device_setting_id === deviceSettings.device_setting_id);
    if(index > -1) {
      if(deviceSettings.data_type === 'boolean') {
        // configData[index].value = data.value;
        configData[index].newValue = data.value;
      } else if (deviceSettings.data_type === 'number') {
        // configData[index].value = data;
        configData[index].newValue = data;
      }
      setConfigData([]);
      setConfigData(list);
    }
    console.log('configDATA: ', configData);
  }

  return (
    <>
    <div id="full-page-content-devices" className="page-content" style={{overflowY: "scroll"}} ref={downloadTargetRef}>
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
    <h5 className='marginZeroEvents'>
      <b>Devices</b>
    </h5>
    <ColumnChooserDevicePopup
        visible={popupVisibleDeviceColumnChooser}
        onHiding={hideInfo}
        dragEnabled={true}
        maxHeight={400}
        width={250}
        shading={false}
        showCloseButton={true}
        showTitle={true}
        title={"Column Chooser"}
        container=".dx-viewport"
        key={'columnchooserDevice'}
      >
        <Position
          at="right top"
          my="right top"
          offset="-200 160"
        />
        {chooserColumn()}
      </ColumnChooserDevicePopup>
      <DataGrid
        showColumnLines={false}
        showRowLines={true}
        height={heightOfGrid}
        dataSource={dataSource}
        showBorders={false}
        onRowUpdating={onRowUpdating}
        onCellPrepared={buttonColor}
        onInitialized={onInitialized}
        ref={dataGridRef}
        columnAutoWidth={false}
        allowColumnResizing={true}
        noDataText={loadingText}
        onExporting={exportGridDevices}
        // filterValue={filterValue}
        // filterSyncEnabled={true}
        // onOptionChanged={onOptionChanged}
      > 
      <Scrolling mode="virtual" />
      <Export enabled={true} />
      <LoadPanel enabled={false} key={'loadpanelDevices'} />
      <Paging enabled={false} key={'pagingDevices'}/>
        <Editing
          allowUpdating={allowUpdating}
          allowDeleting={false}
          allowAdding={false}
          mode="popup"
        >
          <Texts
            confirmDeleteMessage="Are you sure you want to delete this Space?"
          />
          <Popup
            showTitle={true}
            title="Edit Anchor"
          />
          <Form colCount={1} >
            <Item dataField="space.id" isRequired={true}
              editorType="dxSelectBox" editorOptions={spaceEditorOptions}>
              <Label text="Space" />
            </Item>
            <Item dataField="space.proximityDistance" valueChanged={valueChanged} >
              <Label text="Proximity Distance (m)" />
            </Item>
            <Item dataField="statusUpdateRate" >
              <Label text="Status update rate" />
            </Item>
          </Form>
        </Editing>
        <StateStoring enabled={true} type="localStorage" storageKey={myTenant + "devices"} />
        <FilterRow visible={true} apply-filter={true} />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." onTextChange={onStatusUpdate}/>
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear filter', filterEnabledHint: ''}}/>
        {/* <Scrolling mode="infinite" /> */}
        <Column
          dataField={DOMPurify.sanitize('serialNumber')}
          caption={'Serial Number'}
          width={190}
          fixed={true}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={DOMPurify.sanitize('type')}
          caption={'Type'}
          fixed={true}
          allowEditing={false}
        />
        <Column
          dataField={DOMPurify.sanitize('role.name')}
          caption={'Role'}
          fixed={true}
          allowEditing={false}
        />
        <Column
          dataField={DOMPurify.sanitize('space.name')}
          caption={'Space'}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('space.id')}
          caption={'Space'}
          visible={false}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('statusUpdateRate')}
          caption={'Status Update Rate'}
          visible={false}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('space.proximityDistance')}
          dataType={'number'}
          fixed={true}
          width={140}
          caption={'Proximity Distance (m)'}
          alignment="left"
        />
        <Column
          dataField={DOMPurify.sanitize('lastUpdate')}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm a"
          caption={'Last Update'}
          allowFiltering={false}
          allowEditing={false}
          fixed={true}
          width={150}
        />
        <Column
          dataField={DOMPurify.sanitize('battery')}
          calculateDisplayValue={getBattery}
          dataType="string"
          caption={'Battery'}
          allowEditing={false}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('status')}
          caption={'Status'}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('uplinkRate')}
          caption={'Uplink Rate'}
          visible={showColumn('uplinkRate')}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('stationaryUplinkRate')}
          caption={'Stationary Uplink Rate'}
          visible={showColumn('stationaryUplinkRate')}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('motionUplinkRate')}
          visible={showColumn('motionUplinkRate')}
          caption={'Motion Uplink Rate'}
          fixed={true}
        />
        <Column
          dataField={DOMPurify.sanitize('offlineSleepSupported')}
          visible={showColumn('offlineSleepSupported')}
          caption={'Offline Sleep Supported'}
          fixed={true}
          dataType="string"
        />
        <Column type="buttons" visible={toggleEditBool} caption={''} fixed={true}>
          <Button name="edit"  />
        </Column>
        <Column type="buttons" name="custom1" visible={isSpaceConfig && showMapTenantBtn} caption={'Config'} fixed={true} >
          <Button cssClass="customColBtn" name="edit" text="edit setting" visible={isSpaceConfig} icon={SettingsIcon} onClick={openCommonDevicePopup} />
        </Column>
        <ColumnFixing enabled={true} />
      </DataGrid>
      <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
      {/* <div className="columnChooserBtn col_btn" title={"Choose columns"} onClick={columnChooser} key={'uploadbtndevice2linker'}>
        <i className="dx-icon-columnchooser" style={{ "fontSize": "24px", "cursor": "pointer" }}></i>
      </div> */}
      <div className='columnChooserBtn col_btn'>
        <button id="columnChooserBtn" alt="Choose columns" title="Choose columns" type="button" onClick={columnChooser}>
          <img src="../icons/columns.svg" alt="Choose columns" />
        </button>
      </div>
      <div className='uploadBtnAssetsReport upload3 upload3_devices'>
        <button id="generatePdfButton" alt="Download Report" title="Download report" type="button" onClick={() => downloadReport('devices')}>
          <img src="../icons/reports.svg" alt="Download Report" />
        </button>
      </div>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
        key={'toastDevices'}
      />
      <AssignDevicePopup
          visible={showPopup}
          onHiding={onHiding}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title={`Move Device: ${deviceName}`}
          container=".dx-viewport"
          width={500}
          height={350}
      >
        <SelectBox
            placeholder="Choose Tenant"
            focusStateEnabled={false}
            width='450px'
            height={40}
            items={tenants}
            stylingMode="outlined"
            onValueChanged={selectedTenantByUser}
            valueExpr="id"
            displayExpr="name"
            value={selectedTenant}
          >
          </SelectBox>
          <div className='movePopup' style={{paddingTop:"30%", marginLeft:"70%"}}>
            <SelectButton onClick={updateTenant} className='movePopupSaveBtn' style={{ marginRight:"5%"}}>Save</SelectButton>
            <SelectButton onClick={onHiding} className='movePopupSaveBtn' >Cancel</SelectButton>
          </div>
      </AssignDevicePopup>
      <EditDeviceSettingsPopup
        visible={showSettingPopup}
        onHiding={onHidingSettingPopup}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title={`Device Setting for: ${deviceName}`}
        container=".dx-viewport"
      >
        <ScrollView height="100%" width="100%" showScrollbar='always' ref={scrollViewRefEditDevice}>
        <DeviceConfigItems configData={configData} handleChange={handleDeviceSettings} />
        <div className='movePopupEditDEvice' style={{marginLeft:"70%"}}>
          <SelectButton onClick={updateDeviceConfig} className={configData.length > 0 ? 'movePopupSaveBtn' : 'movePopupSaveBtnNoData'} style={{ marginRight:"5%"}}>Save</SelectButton>
          <SelectButton onClick={onHidingSettingPopup} className={configData.length > 0 ? 'movePopupSaveBtn' : 'movePopupSaveBtnNoData'} >Cancel</SelectButton>
        </div>
        </ScrollView>
      </EditDeviceSettingsPopup>
      <CommonEditDevicePopup 
        visible={showCommonSettingPopup}
        onHiding={onHidingCommonEditDevicePopup}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title="Choose action"
        container=".dx-viewport"
        width={300}
        height={250}
        padding={0}
        >
          <div style={{marginLeft: "-20px", marginTop: "-20px", paddingTop: "0px"}}>
            <ul>
              <li style={{lineHeight: "2.5", display: showMapTenantBtn ? 'block' : 'none', cursor:"pointer", textDecoration: "underline", color: "#b51716"}} onClick={() => { openSettingPopup() }}>Edit Configuration</li>
              {/* <li style={{lineHeight: "2.5", display: showMapTenantBtn ? 'block' : 'none' , cursor:"pointer", textDecoration: "underline", color: "#b51716"}} onClick={() => { openDeviceStatusPopup() }}>Manage Device Status</li> */}
              <li style={{lineHeight: "2.5", display: showMapTenantBtn ? 'block' : 'none' , cursor:"pointer", textDecoration: "underline", color: "#b51716"}} onClick={() => { openPopup() }}>Assign Device</li>  
            </ul>
          </div>
      </CommonEditDevicePopup>
      <DeviceStatusUpdatePopup
       visible={showDeviceStatusUpdatePopup}
       onHiding={onHidingDeviceStatusUpdatePopup}
       dragEnabled={false}
       hideOnOutsideClick={true}
       showCloseButton={true}
       showTitle={true}
       title={`Update Device Status: ${deviceName}`}
       container=".dx-viewport"
       width={500}
       height={250}>
        <SelectBox
            placeholder="Choose Status"
            focusStateEnabled={false}
            width='450px'
            height={40}
            items={deviceStatuses}
            stylingMode="outlined"
            onValueChanged={updatedDeviceStatus}
            valueExpr="id"
            displayExpr="name"
            value={selectedStatus}
          >
          </SelectBox>
          <div className='movePopup'>
            <SelectButton onClick={updateDeviceStatus} className='movePopupSaveBtn' style={{ marginRight:"5%"}} disabled={!deviceId || !selectedStatus}>Save</SelectButton>
            <SelectButton onClick={onHidingDeviceStatusUpdatePopup} className='movePopupSaveBtn' >Cancel</SelectButton>
          </div>
      </DeviceStatusUpdatePopup>
      </div>
      </div>
      </div>
    </>
  )
}
